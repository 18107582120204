import React, { useEffect, useRef, useState } from "react";
import StandardContainer from "../../styled/generic/StandardContainer";
import StandardAppContainerRounded from "../../styled/generic/StandardAppContainerRounded";
import Api from "../../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import BoxSpaceBetween from "../../styled/generic/BoxSpaceBetween";
import HorizBox from "../../styled/generic/HorizBox";
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  MenuItem,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import FormBox from "../../styled/generic/FormBox";
import {
  Add,
  BackupTableOutlined,
  CalendarMonthOutlined,
  CheckBoxOutlined,
  TaskAltOutlined,
} from "@mui/icons-material";
import StatusHandler from "../../styled/generic/StatusHandler";
import StatusChip from "../../styled/generic/StatusChip";
import Select from "../../styled/generic/Select";
import Button from "../../styled/generic/Button";
import Dialog from "../../styled/generic/Dialog";
import SpaceBetween from "../../styled/generic/SpaceBetween";
import TextField from "../../styled/generic/TextField";
import dayjs from "dayjs";
import DatePicker from "../../styled/generic/DatePicker";
import DocumentUploader from "../../styled/generic/DocumentUploader";
import DocViewer from "../../styled/generic/DocViewer";
import DuoButtonGroup from "../../styled/generic/DuoButtonGroup";
import DrawerContainer from "../../styled/generic/DrawerContainer";
// import GanttView from "../tasks/GanttView";
import ListView from "../tasks/ListView";
import AddTaskDialog from "../tasks/components/AddTaskDialog";
import CategoriesDrawer from "../tasks/components/CategoriesDrawer";
import CopyTaskDrawer from "../tasks/components/CopyTaskDrawer";
import SearchField from "../../styled/generic/SearchField";
import TabsWithCommonAction from "../../styled/generic/TabsWithCommonAction";
import Updates from "./tabs/Updates";
import SmartCommentList from "../../comment/smart.comment.list";
import StandardAppContainerRoundedWithHeader from "../../styled/generic/StandardAppContainerRoundedWithHeader";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import * as XLSX from "xlsx";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormHeaderWithActions from "../../styled/generic/FormHeaderWithActions";
import useShareAndAssign from "../../ResourceAccessControl/shareAndAssign";

const PlanningTarget = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { targetId } = useParams();
  const history = useHistory();
  const [initialProfileRole, setInitialProfileRole] = useState({});
  const files = useSelector((state) => state.file);
  const { fileDictionary, createdFileIds } = files;
  const assignBtn = (
    <Button variant="outlined" startIcon={<Add />}>
      Click here to assign
    </Button>
  );

  const shareBtn = (
    <Button variant="outlined" color="primary">
        Manage Resource Access
    </Button>  
    )



  const {
    sharedProfilesRole,
    ShareButton,
    ShareDrawer,
    SharedView,
    assignedProfiles,
    AssignButton,
    AssigneDrawer,
    AssignView,
  } = useShareAndAssign({
    initShared: initialProfileRole,
    initAssigned: null,
    resourceId: targetId,
    resourceName: 'Target',
    customShareBtn: shareBtn,
    customAssignBtn: assignBtn,
  });

  const [target, setTarget] = useState();
  const [loading, setLoading] = useState(false);
  const [targetFilter, setTargetFilter] = useState("");
  const [teamFilter, setTeamFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [taskView, setTaskView] = useState("list");
  const [showAddTaskDialog, setShowAddTaskDialog] = useState(false);
  const [taskName, setTaskName] = useState("");
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs().add(1, "day"));
  const [comment, setComment] = useState("");
  const [documents, setDocuments] = useState([]);
  const [documentIds, setDocumentIds] = useState([]);
  const [openCategoryDrawer, setOpenCategoryDrawer] = useState(false);
  const [openTagsDrawer, setOpenTagsDrawer] = useState(false);
  const [categories, setCategories] = useState([]);
  const [labels, setTags] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);
  const [newCategoryName, setNewCategoryName] = useState("");
  const [openCopyTaskDrawer, setOpenCopyTaskDrawer] = useState(false);
  const [reloadTasks, setReloadTasks] = useState(false);
  const [searchStr, setSearchStr] = useState("");
  const [tab, setTab] = useState("Comments");
  const [orderFilter, setOrderFilter] = useState("");
  const [orderFilterOrder, setOrderFilterOrder] = useState("Ascending");
  const [tasksLoading, setTasksLoading] = useState(false);
  const [listTasks, setListTasks] = useState([]);
  const [ganttTasks, setGanttTasks] = useState([]);
  const ORDER_OPTIONS = ["DATE CREATED", "PROGRESS", "START DATE"];
  const [showUploadDialog, setShowUploadDialog] = useState(false);
  const [sheetNames, setSheetNames] = useState([]);
  const [sheets, setSheets] = useState([]);
  const [sheet, setSheet] = useState(null);
  const [selectedSheetName, setSelectedSheetName] = useState(null);
  const [reload, setReload] = useState(false);
  const [userProfileId, setUserProfileId] = useState(null);
  const [orgProfileId, setOrgProfileId] = useState(null);
  const [projectProfileId, setProjectProfileId] = useState(null);

  useEffect(() => {
    console.log("IDS are: ");
    console.log("orgProfileId: ", orgProfileId);
    console.log("userProfileId: ", userProfileId);
    console.log("projectProfileId: ", projectProfileId);
    setUserProfileId(user?.profile);

    let locInitialProfileRole = {};
    if (orgProfileId) {
      locInitialProfileRole[orgProfileId] = "Owner";
    }
    if (userProfileId) {
      locInitialProfileRole[userProfileId] = "Owner";
    }
    if (projectProfileId) {
      locInitialProfileRole[projectProfileId] = "Owner";
    }
    setInitialProfileRole(locInitialProfileRole);
  }, [orgProfileId, userProfileId, projectProfileId]);

  const getTarget = async () => {
    try {
      setLoading(true);
      const { data } = await Api.post("/target/get-by-id", {
        targetId: targetId,
      });
      if (data) {
        setTarget(data);
        console.log("Target is: ", data);
      } else {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "An unknown error occurred while fetching target details",
          },
        });
      }
    } catch (error) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred while fetching target details",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTarget();
  }, []);

  const deleteAllTasks = async () => {
    try {
      await Api.post("/wbs/delete-all");
      setReloadTasks(true);
    } catch (error) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred while deleting all tasks",
        },
      });
    }
  };

  const getTasksForListView = async () => {
    try {
      setTasksLoading(true);
      const { data } = await Api.post("/wbs/", {
        targetId: targetId,
        searchStr: searchStr,
        orderFilter: orderFilter,
        orderFilterOrder: orderFilterOrder,
      });
      if (data) {
        setListTasks(data);
      } else {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "An unknown error occurred while fetching tasks",
          },
        });
      }
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred while fetching tasks",
        },
      });
    } finally {
      setTasksLoading(false);
    }
  };

  useEffect(() => {
    if (searchStr?.trim() !== "" && searchStr?.length > 2) {
      getTasksForListView();
    }
  }, [searchStr]);

  useEffect(() => {
    getTasksForListView();
  }, [, orderFilter, orderFilterOrder]);

  const [fileData, setFileData] = useState(null);

  const handleFileSelection = (e) => {
    if (e.target.files && e.target.files[0]) {
      setFileData(e.target.files[0]);
    }
  };

  const filePickerRef = useRef(null);

  const handleFileUpload = async () => {
    let file = fileData;
    if (file && file.arrayBuffer) {
      const data = await file.arrayBuffer();
      const workbook = XLSX.read(data, { defVal: "" });
      console.log("workbook: ", workbook);

      setSheetNames(workbook.SheetNames);
      setSheets(workbook.Sheets);

      //const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      // const jsonData = XLSX.utils.sheet_to_json(worksheet, {
      //   header: 1,
      //   defval: "",
      // });
      // console.log("jsonData: ", jsonData);
      // setHeaderRowData(jsonData[headerRow]);
      // setSheetData(jsonData);
    } else {
      console.log("No uploaded file found");
    }
  };

  useEffect(() => {
    if (fileData) {
      handleFileUpload();
    }
  }, [fileData]);

  const [selectedTables, setSelectedTables] = useState([]);

  const addTable = () => {
    let tableObj = {
      title: "",
      description: "",
      progress: "",
      isValid: false,
      data: {
        titles: [],
        descriptions: [],
        progresses: [],
      },
    };
    setSelectedTables([...selectedTables, tableObj]);
  };

  useEffect(() => {
    addTable();
  }, []);

  function isValidCellString(str) {
    // Use a regular expression to check if the string matches the pattern
    const pattern = /^\d+[A-Z]$/; // Matches one or more digits followed by a single uppercase letter
    return pattern.test(str); // Eg. 100A: true, 100AB: false
  }

  const handleTableValidation = (index, key, _value) => {
    let value = String(_value).toUpperCase();

    if (key === "title") {
      setSelectedTables((prev) => {
        let newTables = [...prev];
        newTables[index].title = value;
        //newTables[index].data.titles = [];
        return newTables;
      });
    } else if (key === "description") {
      setSelectedTables((prev) => {
        let newTables = [...prev];
        newTables[index].description = value;
        //newTables[index].data.descriptions = [];
        return newTables;
      });
    } else if (key === "progress") {
      setSelectedTables((prev) => {
        let newTables = [...prev];
        newTables[index].progress = value;
        //newTables[index].data.progresses = [];
        return newTables;
      });
    }

    let newTables = [...selectedTables];
    let table = newTables[index];
    let isValid = false;
    let sheet = sheets[selectedSheetName];
    console.log("Sheet is: ", sheet);

    // Check if value is valid i.e in format of 100A:200A
    let split = value.split(":");
    if (split.length !== 2) {
      isValid = false;
      console.log("Invalid split");
      return;
    } else {
      let start = split[0]; // 110A
      let end = split[1]; // 200A

      // Check if start and end are valid
      if (!isValidCellString(start)) {
        console.log("Start is invalid");
        isValid = false;
        return;
      }

      if (!isValidCellString(end)) {
        console.log("End is invalid");
        isValid = false;
        return;
      }

      // Now we are sure that start and end are valid eg. 110A and 200A

      let startLetter = start.slice(-1);
      let endLetter = end.slice(-1);
      console.log("Start letter: ", startLetter);
      console.log("End letter: ", endLetter);

      if (startLetter !== endLetter) {
        isValid = false;
        console.log("Col letters dont match");
      } else {
        let startNumber = start.slice(0, -1);
        let endNumber = end.slice(0, -1);
        console.log("start: ", start);
        console.log("end: ", end);
        console.log("Start Number: ", startNumber);
        console.log("End Number: ", endNumber);

        if (isNaN(startNumber) || isNaN(endNumber)) {
          console.log("Start or end Number is invalid");
          isValid = false;
        }

        startNumber = parseFloat(startNumber);
        endNumber = parseFloat(endNumber);

        if (startNumber > endNumber) {
          isValid = false;
          console.log("Start num cant be more than end num");
        } else {
          // Form the range
          let range = [];
          console.log("Start num: ", startNumber);
          console.log("End num: ", endNumber);

          for (let i = startNumber; i <= endNumber; i++) {
            range.push(startLetter + i);
          }
          console.log("Range is: ", range);

          console.log("Object entries: ", Object.entries(sheet));

          // Insert data

          for (let i = 0; i < range.length; i++) {
            //console.log("RANGE LOOP: ", i);
            let cell = range[i];

            let data = sheet[cell];
            console.log("CELL: ", cell);
            console.log("DATA: ", data);
            if (!data) {
              if (key === "title") {
                table.data.titles.push("");
              } else if (key === "description") {
                table.data.descriptions.push("");
              } else if (key === "progress") {
                table.data.progresses.push("");
              } else {
                console.log("Invalid key");
              }
              isValid = true;
            } else {
              let sheetVal = data?.v || data?.h || data?.w || "";
              if (key === "title") {
                table.data.titles.push(sheetVal);
              } else if (key === "description") {
                table.data.descriptions.push(sheetVal);
              } else if (key === "progress") {
                table.data.progresses.push(sheetVal);
              } else {
                console.log("Invalid key");
              }
              isValid = true;
            }
          }
        }

        // Update table state
        table.isValid = isValid;
        newTables[index] = table;
        setSelectedTables(newTables);
        console.log("New tables: ", newTables);
      }
    }
  };

  const handlePreupload = () => {
    let max = 0;
    let tables = [...selectedTables];
    for (let i = 0; i < tables?.length; i++) {
      let table = tables[i];
      let data = table?.data;
      let titles = data?.titles;
      let descriptions = data?.descriptions;
      let progresses = data?.progresses;
      max = titles?.length;
      if (descriptions?.length > max) {
        max = descriptions?.length;
      } else if (progresses?.length > max) {
        max = progresses?.length;
      }
      // Make all arrays equal in length
      for (let j = titles?.length; j <= max; j++) {
        titles.push("");
      }

      for (let j = descriptions?.length; j <= max; j++) {
        descriptions.push("");
      }

      for (let j = progresses?.length; j <= max; j++) {
        progresses.push("");
      }
    }
    console.log("FINAL TABLES TO UPLOAD: ");
    console.log(tables);

    // Merge them
    let tablesObjects = [];
    for (let i = 0; i < tables?.length; i++) {
      let table = tables[i];
      let data = table?.data;
      for (let j = 0; j < max; j++) {
        let parsedPerc = String(data.progresses[j]).replace("%", "");
        let obj = {
          title: data.titles[j],
          description: data.descriptions[j],
          progress: parsedPerc,
        };
        tablesObjects.push(obj);
      }
    }

    return tablesObjects;
  };

  const uploadTasks = async () => {
    let tasks = handlePreupload();
    console.log("Table objects: ", tasks);
    try {
      const { data } = await Api.post("/wbs/upload", {
        targetId: targetId,
        tasks: tasks,
        creatorProfileId: user?.profile,
        shared:
          sharedProfilesRole && sharedProfilesRole.length > 0
            ? sharedProfilesRole.map((s) => s?._id)
            : [],
        assigned:
          assignedProfiles && assignedProfiles.length > 0
            ? assignedProfiles.map((s) => s?._id)
            : [],
        accessRoles: sharedProfilesRole,
      });
      if (data) {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Tasks uploaded successfully",
          },
        });
        setShowUploadDialog(false);
        setReload(!reload);
      }
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred while uploading tasks",
        },
      });
    }
  };

  useEffect(() => {
    if (!openCategoryDrawer) {
      setFileData(null);
    }
  }, [openCategoryDrawer]);

  return (
    <StandardContainer
      showAppBar={true}
      appBarTitle={target?.title || "Untitled"}
      loading={loading}
      appBarActions={
        <HorizBox>
          {ShareButton}
          {ShareDrawer}
          {/* <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              history.push(`/labels/${target?.buildingCodeLibrary}`);
            }}
          >
            Labels
          </Button> */}
        </HorizBox>
      }
    >
      <StandardAppContainerRoundedWithHeader
        title="Tasks"
        actions={
          <HorizBox>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setShowUploadDialog(true)}
            >
              Upload
            </Button>

            <ToggleButtonGroup
              value={taskView}
              exclusive
              onChange={(evt, val) => setTaskView(val)}
              size="small"
              sx={{ marginLeft: "10px" }}
            >
              <ToggleButton value="list">
                <TaskAltOutlined />
              </ToggleButton>
              {/* <ToggleButton value="gantt">
                <BackupTableOutlined />
              </ToggleButton> */}
            </ToggleButtonGroup>
          </HorizBox>
        }
        fullHeight={true}
      >
        {/* <Box>
          {taskView === "gantt" ? (
            // <GanttView />
            <></>
          ) : (
            <ListView target={target} reload={reload} />
          )}
        </Box> */}
        <ListView target={target} reload={reload} />

      </StandardAppContainerRoundedWithHeader>
      <DrawerContainer
        open={showUploadDialog}
        setOpen={setShowUploadDialog}
        title="Upload Tasks From Excel"
      >
        <Box>
          <Box>
            {fileData ? (
              <Box>
                <FormBox label="Select Sheet">
                  <RadioGroup
                    row
                    value={selectedSheetName}
                    onChange={(e) => setSelectedSheetName(e.target.value)}
                  >
                    {sheetNames.map((sheetName) => (
                      <FormControlLabel
                        value={sheetName}
                        control={<Radio />}
                        label={sheetName}
                      />
                    ))}
                  </RadioGroup>
                </FormBox>
                <FormHeaderWithActions
                  label="Tables"
                  actions={
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => addTable()}
                    >
                      Add New Table
                    </Button>
                  }
                />
                {!selectedSheetName ? (
                  <Box>
                    <Typography variant="body1">
                      Please select a sheet
                    </Typography>
                  </Box>
                ) : (
                  <Box>
                    {selectedTables.map((table, index) => {
                      return (
                        <Box sx={{ mt: 2 }}>
                          {!table?.isValid && (
                            <Box sx={{ my: 1 }}>
                              Please properly fill all the fields, 10A:20A or
                              30E:90E for example
                            </Box>
                          )}
                          <Grid container spacing={2}>
                            <Grid item xs={4}>
                              <TextField
                                label="Title"
                                value={table.title}
                                onChange={(e) =>
                                  handleTableValidation(
                                    index,
                                    "title",
                                    e.target.value
                                  )
                                }
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <TextField
                                label="Description"
                                value={table.description}
                                onChange={(e) =>
                                  handleTableValidation(
                                    index,
                                    "description",
                                    e.target.value
                                  )
                                }
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <TextField
                                label="Progress"
                                value={table.progress}
                                onChange={(e) =>
                                  handleTableValidation(
                                    index,
                                    "progress",
                                    e.target.value
                                  )
                                }
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      );
                    })}
                  </Box>
                )}
                <FormBox label="Assignees">
                  {AssignButton} {AssigneDrawer}
                  <div>{AssignView}</div>
                </FormBox>
                <DuoButtonGroup
                  primaryButtonText="Upload"
                  primaryButtonListener={() => uploadTasks()}
                  secondaryButtonText="Cancel"
                  secondaryButtonListener={() => setShowAddTaskDialog(false)}
                  disablePrimaryButton={
                    selectedTables.length === 0 || !selectedSheetName
                  }
                />
              </Box>
            ) : (
              <Box>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => filePickerRef.current.click()}
                >
                  Click here to select file
                </Button>
                <input
                  type="file"
                  multiple={false}
                  hidden
                  ref={filePickerRef}
                  onChange={handleFileSelection}
                  accept=".xlsx, .xls"
                />
              </Box>
            )}
          </Box>
        </Box>
      </DrawerContainer>
    </StandardContainer>
  );
};
export default PlanningTarget;

// import React, { useEffect, useState } from "react";
// import StandardContainer from "../../styled/generic/StandardContainer";
// import StandardAppContainerRounded from "../../styled/generic/StandardAppContainerRounded";
// import "../../../App.css";
// import Api from "../../../helpers/Api";
// import { useDispatch, useSelector } from "react-redux";
// import { useParams } from "react-router-dom/cjs/react-router-dom.min";
// import BoxSpaceBetween from "../../styled/generic/BoxSpaceBetween";
// import HorizBox from "../../styled/generic/HorizBox";
// import {
//   Avatar,
//   Box,
//   Grid,
//   IconButton,
//   MenuItem,
//   ToggleButton,
//   ToggleButtonGroup,
//   Typography,
// } from "@mui/material";
// import FormBox from "../../styled/generic/FormBox";
// import {
//   Add,
//   BackupTableOutlined,
//   CalendarMonthOutlined,
//   CheckBoxOutlined,
//   TaskAltOutlined,
// } from "@mui/icons-material";
// import StatusHandler from "../../styled/generic/StatusHandler";
// import StatusChip from "../../styled/generic/StatusChip";
// import Select from "../../styled/generic/Select";
// import Button from "../../styled/generic/Button";
// import Dialog from "../../styled/generic/Dialog";
// import SpaceBetween from "../../styled/generic/SpaceBetween";
// import TextField from "../../styled/generic/TextField";
// import dayjs from "dayjs";
// import DatePicker from "../../styled/generic/DatePicker";
// import DocumentUploader from "../../styled/generic/DocumentUploader";
// import DocViewer from "../../styled/generic/DocViewer";
// import DuoButtonGroup from "../../styled/generic/DuoButtonGroup";
// import DrawerContainer from "../../styled/generic/DrawerContainer";
// import GanttView from "../tasks/GanttView";
// import ListView from "../tasks/ListView";
// import AddTaskDialog from "../tasks/components/AddTaskDialog";
// import CategoriesDrawer from "../tasks/components/CategoriesDrawer";
// import CopyTaskDrawer from "../tasks/components/CopyTaskDrawer";
// import SearchField from "../../styled/generic/SearchField";
// import TabsWithCommonAction from "../../styled/generic/TabsWithCommonAction";
// import Updates from "./tabs/Updates";
// import SmartCommentList from "../../comment/smart.comment.list";

// const PlanningTarget = () => {
//   const dispatch = useDispatch();
//   const { targetId } = useParams();
//   const files = useSelector((state) => state.file);
//   const { fileDictionary, createdFileIds } = files;
//   const [target, setTarget] = useState();
//   const [loading, setLoading] = useState(false);
//   const [targetFilter, setTargetFilter] = useState("");
//   const [teamFilter, setTeamFilter] = useState("");
//   const [statusFilter, setStatusFilter] = useState("");
//   const [taskView, setTaskView] = useState("list");
//   const [showAddTaskDialog, setShowAddTaskDialog] = useState(false);
//   const [taskName, setTaskName] = useState("");
//   const [startDate, setStartDate] = useState(dayjs());
//   const [endDate, setEndDate] = useState(dayjs().add(1, "day"));
//   const [comment, setComment] = useState("");
//   const [documents, setDocuments] = useState([]);
//   const [documentIds, setDocumentIds] = useState([]);
//   const [openCategoryDrawer, setOpenCategoryDrawer] = useState(false);
//   const [openTagsDrawer, setOpenTagsDrawer] = useState(false);
//   const [categories, setCategories] = useState([]);
//   const [labels, setTags] = useState([]);
//   const [selectedCategory, setSelectedCategory] = useState("");
//   const [selectedTags, setSelectedTags] = useState([]);
//   const [newCategoryName, setNewCategoryName] = useState("");
//   const [openCopyTaskDrawer, setOpenCopyTaskDrawer] = useState(false);
//   const [reloadTasks, setReloadTasks] = useState(false);
//   const [searchStr, setSearchStr] = useState("");
//   const [tab, setTab] = useState("Comments");
//   const [orderFilter, setOrderFilter] = useState("");
//   const [orderFilterOrder, setOrderFilterOrder] = useState("Ascending");
//   const [tasksLoading, setTasksLoading] = useState(false);
//   const [listTasks, setListTasks] = useState([]);
//   const [ganttTasks, setGanttTasks] = useState([]);
//   const ORDER_OPTIONS = ["DATE CREATED", "PROGRESS", "START DATE"];

//   const getTarget = async () => {
//     try {
//       setLoading(true);
//       const { data } = await Api.post("/target/get-by-id", {
//         targetId: targetId,
//       });
//       if (data) {
//         setTarget(data);
//       } else {
//         dispatch({
//           type: "AddApiAlert",
//           payload: {
//             success: false,
//             message: "An unknown error occurred while fetching target details",
//           },
//         });
//       }
//     } catch (error) {
//       dispatch({
//         type: "AddApiAlert",
//         payload: {
//           success: false,
//           message: "An unknown error occurred while fetching target details",
//         },
//       });
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     getTarget();
//   }, []);

//   const deleteAllTasks = async () => {
//     try {
//       await Api.post("/wbs/delete-all");
//       setReloadTasks(true);
//     } catch (error) {
//       dispatch({
//         type: "AddApiAlert",
//         payload: {
//           success: false,
//           message: "An unknown error occurred while deleting all tasks",
//         },
//       });
//     }
//   };

//   const getTasksForListView = async () => {
//     try {
//       setTasksLoading(true);
//       const { data } = await Api.post("/wbs/", {
//         targetId: targetId,
//         searchStr: searchStr,
//         orderFilter: orderFilter,
//         orderFilterOrder: orderFilterOrder,
//       });
//       if (data) {
//         setListTasks(data);
//       } else {
//         dispatch({
//           type: "AddApiAlert",
//           payload: {
//             success: false,
//             message: "An unknown error occurred while fetching tasks",
//           },
//         });
//       }
//     } catch (err) {
//       dispatch({
//         type: "AddApiAlert",
//         payload: {
//           success: false,
//           message: "An unknown error occurred while fetching tasks",
//         },
//       });
//     } finally {
//       setTasksLoading(false);
//     }
//   };

//   useEffect(() => {
//     if (searchStr?.trim() !== "" && searchStr?.length > 2) {
//       getTasksForListView();
//     }
//   }, [searchStr]);

//   useEffect(() => {
//     getTasksForListView();
//   }, [, orderFilter, orderFilterOrder]);

//   return (
//     <StandardContainer
//       showAppBar={true}
//       appBarTitle={target?.title || "Untitled"}
//       loading={loading}
//       appBarActions={
//         <HorizBox>
//           <Button variant="text" color="primary">
//             Manage Resource Access
//           </Button>
//         </HorizBox>
//       }
//     >
//       <Box
//         sx={{
//           borderRadius: "12px",
//           overflow: "hidden",
//           border: "1px solid #E5E8EC",
//           boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.10)",
//         }}
//       >
//         <Box
//           sx={{
//             backgroundColor: "#E5E8EC",
//             p: 2,
//           }}
//         >
//           <BoxSpaceBetween>
//             <HorizBox>
//               <svg
//                 xmlns="http://www.w3.org/2000/svg"
//                 width="24"
//                 height="23"
//                 viewBox="0 0 24 23"
//                 fill="none"
//               >
//                 <path
//                   d="M22.1435 12.3039C21.989 14.2456 21.2803 16.102 20.1018 17.6528C18.9232 19.2035 17.3243 20.3833 15.495 21.0521C13.6656 21.7209 11.6827 21.8504 9.78191 21.4254C7.88109 21.0004 6.14221 20.0387 4.77188 18.6545C3.40154 17.2704 2.45735 15.5219 2.05147 13.6169C1.64559 11.7119 1.79509 9.73044 2.4822 7.9079C3.16931 6.08537 4.36516 4.49838 5.92767 3.33548C7.49017 2.17258 9.35366 1.48265 11.2967 1.34766"
//                   stroke="#2D76E0"
//                   stroke-width="1.75"
//                   stroke-linecap="round"
//                   stroke-linejoin="round"
//                 />
//                 <path
//                   d="M16.3614 13.204C16.0788 13.9315 15.6193 14.577 15.0244 15.0823C14.4296 15.5875 13.7182 15.9366 12.9545 16.0978C12.1909 16.259 11.3991 16.2273 10.6508 16.0056C9.90247 15.7839 9.22122 15.3792 8.66863 14.828C8.11605 14.2768 7.70957 13.5966 7.48594 12.8489C7.26231 12.1011 7.2286 11.3095 7.38784 10.5454C7.54709 9.78135 7.89428 9.06905 8.398 8.4729C8.90173 7.87675 9.54611 7.41557 10.2729 7.13105M11.9946 11.4979L15.9075 7.58496M15.9075 7.58496L19.0379 8.36755L22.1683 5.23718L19.0379 4.45459L18.2553 1.32422L15.1249 4.45459L15.9075 7.58496Z"
//                   stroke="#2D76E0"
//                   stroke-width="1.75"
//                   stroke-linecap="round"
//                   stroke-linejoin="round"
//                 />
//               </svg>
//               <Typography variant="h6" color="primary">
//                 {target?.title || "Untitled"}
//               </Typography>
//             </HorizBox>
//             <Box
//               sx={{
//                 borderRadius: "32px",
//                 padding: "5px 10px",
//                 backgroundColor: "#FCEDED",
//                 border: "2px solid #E97C7C",
//                 color: "#E97C7C",
//               }}
//             >
//               <Typography
//                 variant="subtitle1"
//                 sx={{
//                   color: "#E97C7C",
//                 }}
//               >
//                 40% Completed
//               </Typography>
//             </Box>
//           </BoxSpaceBetween>
//         </Box>
//         <Box
//           sx={{
//             p: 2,
//           }}
//         >
//           <Box display="flex" alignItems="start" justifyContent="space-between">
//             <Box flex="0.3">
//               <Box>
//                 <Grid container spacing={2}>
//                   <Grid item xs={6}>
//                     <Typography variant="subtitle1">Start Date</Typography>
//                   </Grid>
//                   <Grid item xs={6}>
//                     <HorizBox spacing={1}>
//                       <CalendarMonthOutlined />
//                       <Typography
//                         variant="subtitle1"
//                         sx={{
//                           fontWeight: "bold",
//                         }}
//                       >
//                         01 Feb 23
//                       </Typography>
//                     </HorizBox>
//                   </Grid>
//                   <Grid item xs={6}>
//                     <Typography variant="subtitle1">End Date</Typography>
//                   </Grid>
//                   <Grid item xs={6}>
//                     <HorizBox spacing={1}>
//                       <CalendarMonthOutlined />
//                       <Typography
//                         variant="subtitle1"
//                         sx={{
//                           fontWeight: "bold",
//                         }}
//                       >
//                         01 Feb 23
//                       </Typography>
//                     </HorizBox>
//                   </Grid>
//                   <Grid item xs={6}>
//                     <Typography variant="subtitle1">Organization</Typography>
//                   </Grid>
//                   <Grid item xs={6}>
//                     {target?.organization?._id ? (
//                       <div
//                         style={{
//                           border: "1px solid rgba(0,0,0,0.10)",
//                           padding: "5px 15px",
//                           borderRadius: "12px",
//                           fontWeight: "normal",
//                           display: "inline-flex",
//                           alignItems: "center",
//                         }}
//                       >
//                         <Avatar
//                           sx={{ width: "24px", height: "24px" }}
//                           src={target?.organization?.displayPicture?.url}
//                         />
//                         <Typography
//                           variant="subtitle1"
//                           sx={{ marginLeft: "6px", fontWeight: "500" }}
//                         >
//                           {target?.organization?.displayName}
//                         </Typography>
//                       </div>
//                     ) : (
//                       "-"
//                     )}
//                   </Grid>
//                   <Grid item xs={6}>
//                     <Typography variant="subtitle1">Project</Typography>
//                   </Grid>
//                   <Grid item xs={6}>
//                     {target?.project?._id ? (
//                       <div
//                         style={{
//                           border: "1px solid rgba(0,0,0,0.10)",
//                           padding: "5px 15px",
//                           borderRadius: "12px",
//                           fontWeight: "normal",
//                           display: "inline-flex",
//                           alignItems: "center",
//                         }}
//                       >
//                         <Avatar
//                           sx={{ width: "24px", height: "24px" }}
//                           src={target?.project?.displayPicture?.url}
//                         />
//                         <Typography
//                           variant="subtitle1"
//                           sx={{ marginLeft: "6px", fontWeight: "500" }}
//                         >
//                           {target?.project?.displayName}
//                         </Typography>
//                       </div>
//                     ) : (
//                       "-"
//                     )}
//                   </Grid>
//                 </Grid>
//               </Box>
//             </Box>
//             <Box flex="0.4">
//               <Grid container spacing={2}>
//                 <Grid item xs={6}>
//                   <Typography variant="subtitle1">Tags</Typography>
//                 </Grid>
//                 <Grid item xs={6}>
//                   <Typography
//                     variant="subtitle1"
//                     sx={{
//                       fontWeight: "bold",
//                     }}
//                   >
//                     0 Tags
//                   </Typography>
//                 </Grid>
//                 <Grid item xs={6}>
//                   <Typography variant="subtitle1">Description</Typography>
//                 </Grid>
//                 <Grid item xs={6}>
//                   <Typography
//                     variant="subtitle1"
//                     sx={{
//                       fontWeight: "bold",
//                     }}
//                   >
//                     {target?.description || "No Description"}
//                   </Typography>
//                 </Grid>
//               </Grid>
//             </Box>
//           </Box>
//         </Box>
//       </Box>

//       <Box
//         sx={{
//           borderRadius: "12px",
//           overflow: "hidden",
//           boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.10)",
//           mt: 3,
//         }}
//       >
//         <Box
//           sx={{
//             backgroundColor: "#E5E8EC",
//             p: 2,
//           }}
//         >
//           <BoxSpaceBetween>
//             <HorizBox>
//               <svg
//                 width="25"
//                 height="24"
//                 viewBox="0 0 25 24"
//                 fill="none"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <g id="vuesax/linear/task-square">
//                   <g id="task-square">
//                     <path
//                       id="Vector"
//                       d="M12.8672 8.87891H18.1172"
//                       stroke="#2D76E0"
//                       stroke-width="1.5"
//                       stroke-linecap="round"
//                       stroke-linejoin="round"
//                     />
//                     <path
//                       id="Vector_2"
//                       d="M6.88281 8.87891L7.63281 9.62891L9.88281 7.37891"
//                       stroke="#2D76E0"
//                       stroke-width="1.5"
//                       stroke-linecap="round"
//                       stroke-linejoin="round"
//                     />
//                     <path
//                       id="Vector_3"
//                       d="M12.8672 15.8789H18.1172"
//                       stroke="#2D76E0"
//                       stroke-width="1.5"
//                       stroke-linecap="round"
//                       stroke-linejoin="round"
//                     />
//                     <path
//                       id="Vector_4"
//                       d="M6.88281 15.8789L7.63281 16.6289L9.88281 14.3789"
//                       stroke="#2D76E0"
//                       stroke-width="1.5"
//                       stroke-linecap="round"
//                       stroke-linejoin="round"
//                     />
//                     <path
//                       id="Vector_5"
//                       d="M9.5 22H15.5C20.5 22 22.5 20 22.5 15V9C22.5 4 20.5 2 15.5 2H9.5C4.5 2 2.5 4 2.5 9V15C2.5 20 4.5 22 9.5 22Z"
//                       stroke="#2D76E0"
//                       stroke-width="1.5"
//                       stroke-linecap="round"
//                       stroke-linejoin="round"
//                     />
//                   </g>
//                 </g>
//               </svg>

//               <Typography variant="h6" color="primary">
//                 Tasks
//               </Typography>
//             </HorizBox>
//             <Box>
//               <HorizBox>
//                 <Button
//                   variant="text"
//                   onClick={() => {
//                     deleteAllTasks();
//                   }}
//                   color="error"
//                 >
//                   Delete All
//                 </Button>
//                 <Button
//                   onClick={() => {
//                     setOpenCopyTaskDrawer(true);
//                   }}
//                   variant="outlined"
//                   color="primary"
//                 >
//                   Copy Task
//                 </Button>

//                 <Button
//                   variant="contained"
//                   color="primary"
//                   startIcon={<Add />}
//                   onClick={() => {
//                     setShowAddTaskDialog(true);
//                   }}
//                 >
//                   Add Task
//                 </Button>
//               </HorizBox>
//             </Box>
//           </BoxSpaceBetween>
//         </Box>
//         <Box
//           sx={{
//             p: 2,
//           }}
//         >
//           <BoxSpaceBetween>
//             <Box flex="0.5">
//               <SearchField
//                 value={searchStr}
//                 onChange={(e) => setSearchStr(e.target.value)}
//                 sx={{
//                   backgroundColor: "white",
//                   boxShadow: "0px 4px 5px 0px rgba(0, 0, 0, 0.04)",
//                 }}
//                 fullWidth={true}
//                 size="small"
//               />
//             </Box>

//             <HorizBox>
//               <Select
//                 value={orderFilter}
//                 onChange={(e) => setOrderFilter(e.target.value)}
//                 displayEmpty
//                 size="small"
//               >
//                 <MenuItem value="">Filter (None)</MenuItem>
//                 {ORDER_OPTIONS.map((option) => (
//                   <MenuItem value={option}>{option}</MenuItem>
//                 ))}
//               </Select>

//               <Select
//                 value={orderFilterOrder}
//                 onChange={(e) => setOrderFilterOrder(e.target.value)}
//                 displayEmpty
//                 size="small"
//               >
//                 <MenuItem value="Ascending">Ascending</MenuItem>
//                 <MenuItem value="Descending">Descending</MenuItem>
//               </Select>

//               <ToggleButtonGroup
//                 value={taskView}
//                 exclusive
//                 onChange={(evt, val) => setTaskView(val)}
//                 size="small"
//                 sx={{ marginLeft: "10px" }}
//               >
//                 <ToggleButton value="list">
//                   <TaskAltOutlined />
//                 </ToggleButton>
//                 <ToggleButton value="gantt">
//                   <BackupTableOutlined />
//                 </ToggleButton>
//               </ToggleButtonGroup>
//             </HorizBox>
//           </BoxSpaceBetween>

//           <Box sx={{ mt: 2 }}>
//             <Box>
//               {taskView === "gantt" ? (
//                 <GanttView />
//               ) : (
//                 <ListView
//                   getTasks={getTasksForListView}
//                   tasks={listTasks}
//                   setTasks={setListTasks}
//                   loading={tasksLoading}
//                   setLoading={setTasksLoading}
//                 />
//               )}
//             </Box>
//           </Box>
//         </Box>
//       </Box>

//       <StandardAppContainerRounded>
//         <TabsWithCommonAction
//           tabs={["Comments", "Updates"]}
//           onTabChange={(data) => setTab(data)}
//           currentTabVariable={tab}
//           action={<></>}
//         />
//         {tab === "Updates" && <Updates />}
//         {tab === "Comments" && (
//           <SmartCommentList parent={target?._id} parentModelName="Target" />
//         )}
//       </StandardAppContainerRounded>

//       <AddTaskDialog
//         showAddTaskDialog={showAddTaskDialog}
//         setShowAddTaskDialog={setShowAddTaskDialog}
//         targetId={targetId}
//         onTaskAdd={() => {
//           setReloadTasks(true);
//         }}
//         profileId={target?.organization?.profile}
//         libraryId={target?.buildingCodeLibrary}
//         project={target?.project?._id}
//         organization={target?.organization?._id}
//         user={target?.user?._id}
//       />
//       <CopyTaskDrawer
//         openCopyTaskDrawer={openCopyTaskDrawer}
//         setOpenCopyTaskDrawer={setOpenCopyTaskDrawer}
//         profileIdToLoadTargetsFrom={target?.organization?.profile}
//         onTaskCopy={() => {
//           setReloadTasks(true);
//         }}
//       />
//     </StandardContainer>
//   );
// };
// export default PlanningTarget;
