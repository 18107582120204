import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";

import DoneAllIcon from "@material-ui/icons/DoneAll";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import ShoppingBasketOutlinedIcon from "@material-ui/icons/ShoppingBasketOutlined";

import {
  Button,
  Chip,
  CircularProgress,
  Input,
  InputAdornment,
  OutlinedInput,
  Popover,
  TextField,
  makeStyles,
} from "@material-ui/core";

import ProfileAppbar from "../profile/profile.appbar";
import {
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import * as XLSX from "xlsx";
import Api from "../../helpers/Api";
import configObject from "../../config";
import axios from "axios";
import SharedList from "../share/sharedList";
import useShared from "../share/useShared";
import ShareIconBtn from "../share/share.icon.btn";
import { useDebounce } from "react-use";
import { FormControl, Grid, MenuItem, Pagination, Select } from "@mui/material";
import { Add } from "@material-ui/icons";
import NormalDialog from "../styled/CommonComponents/NormalDialog";

const useStyles = makeStyles({
  outlinedInput: {
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: 4,
    padding: "7px 10px", // Adjust padding as needed
    "&:focus": {
      borderColor: "#2196f3",
    },
  },
});

function EnhancedTableHead(props) {
  const { onSelectAllClick, numSelected, rowCount, columns } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all",
            }}
          />
        </TableCell>
        {columns.map((col) => {
          return <TableCell key={col.id}>{col.headerName}</TableCell>;
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const {
    numSelected,
    searchQuery,
    setSearchQuery,
    openFilterMenu,
    title,
    setShowAddProductDialog,
    deleteAll,
  } = props;
  const classes = useStyles();
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Box display="flex" alignItems="center" style={{ width: "100%" }}>
          <ShoppingBasketOutlinedIcon />
          <Typography
            sx={{ flex: "1 1 100%", marginLeft: 1 }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {title}
          </Typography>
        </Box>
      )}

      {numSelected > 0 ? (
        <Box display="flex" alignItems="center">
          <Tooltip title="Approve">
            <IconButton onClick={() => deleteAll()}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ) : (
        <>
          <Input
            type="text"
            size="small"
            placeholder="Search..."
            classes={{
              root: classes.outlinedInput,
            }}
            disableUnderline
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  onClick={(e) => openFilterMenu(e.currentTarget)}
                >
                  <FilterListIcon />
                </IconButton>
              </InputAdornment>
            }
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <Button
            color="primary"
            variant="contained"
            endIcon={<Add />}
            onClick={() => setShowAddProductDialog(true)}
            style={{ marginLeft: "15px" }}
          >
            Add
          </Button>
        </>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const ProjectLibraryView = ({ libraryId }) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const PAGE_SIZE = 10;

  const [library, setLibrary] = useState({});
  const [resources, setResources] = useState([]);
  const [rows, setRows] = useState([]);
  const [selected, setSelected] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [showFilterMenu, setShowFilterMenu] = useState(false);
  const [filterMenuAnchor, setFilterMenuAnchor] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState("");
  const [subCategoryFilter, setSubCategoryFilter] = useState("");
  const [shouldRefetch, setShouldRefetch] = useState(true);
  const [showAddProductDialog, setShowAddProductDialog] = useState(false);
  const [productTitle, setProductTitle] = useState("");
  const [productCategory, setProductCategory] = useState("");
  const [productSubCategory, setProductSubCategory] = useState("");
  const [productUnit, setProductUnit] = useState("");

  useEffect(() => {
    if (categoryFilter === "") {
      setSubCategoryFilter("");
    } else {
      let libSubCategories = library?.subCategories;
      let subCatList = [];
      libSubCategories.forEach((item) => {
        if (item.category === categoryFilter) {
          subCatList.push(item.name);
        }
      });
      setSubCategories(subCatList);
    }
  }, [categoryFilter]);

  const processLibraryData = (list) => {
    if (list.length > 0) {
      const newList = list.map((item, index) => {
        return {
          id: index + 1,
          _id: item._id,
          name: item.name,
          category: item.category,
          subCategory: item.subCategory,
          unit: item.unit,
        };
      });
      return newList;
    } else {
      return [];
    }
  };

  const getLibraryData = async () => {
    try {
      setLoading(true);
      const data = await Api.post("/estimate/resources", {
        libraryId: libraryId,
        pageNumber: parseInt(currentPage),
        pageSize: PAGE_SIZE,
        categoryFilter: categoryFilter,
        subCategoryFilter: subCategoryFilter,
        searchQuery: searchQuery,
      });
      setLibrary(data.library);
      setResources(data.resources);
      setTotalPages(data.totalPages);
      setCategories(data.library?.categories || []);
      // library, resources, totalPages, pageNumber, pageSize
      const rowData = processLibraryData(data.resources);
      setRows(rowData);
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred" + err,
        },
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (shouldRefetch) {
      getLibraryData();
      setShouldRefetch(false);
    }
  }, [currentPage, shouldRefetch]);

  const columns = [
    { id: "mId", field: "id", headerName: "Material ID" },
    { id: "mName", field: "name", headerName: "Material Name" },
    { id: "mCategory", field: "category", headerName: "Category" },
    { id: "mSubCategory", field: "subCategory", headerName: "Sub Category" },
    { id: "mUnit", field: "unit", headerName: "UOM" },
  ];

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n._id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const applyFilters = async () => {
    setShouldRefetch(true);
  };

  useDebounce(
    () => {
      applyFilters();
    },
    500,
    [searchQuery]
  );

  const openFilterMenu = (anchor) => {
    setShowFilterMenu(true);
    setFilterMenuAnchor(anchor);
  };

  const closeFilterMenu = () => {
    setCategoryFilter("");
    setSubCategoryFilter("");
    setShowFilterMenu(false);
    setFilterMenuAnchor(null);
  };

  const addNewItemToLib = async () => {
    if (
      !productTitle ||
      !productCategory ||
      !productSubCategory ||
      !productUnit
    ) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Please fill all fields",
        },
      });
      return;
    }

    try {
      setLoading(true);
      const { data } = await Api.post("/estimate/add-item", {
        codeLibrary: libraryId,
        name: productTitle,
        category: productCategory,
        subCategory: productSubCategory,
        unit: productUnit,
      });
      let newRes = [data, ...resources];
      setResources(newRes);
      const rowData = processLibraryData(newRes);
      setRows(rowData);
      setShowAddProductDialog(false);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Item added successfully",
        },
      });
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred" + err,
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const deleteAll = async () => {
    try {
      setLoading(true);
      const { data } = await Api.post("/estimate/delete-items", {
        itemArr: selected,
      });
      let newRes = resources.filter(
        (element) => selected.indexOf(element) !== -1
      );
      setSelected([]);
      setResources(newRes);
      const rowData = processLibraryData(newRes);
      setRows(rowData);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Items deleted successfully",
        },
      });
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred" + err,
        },
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%" }} elevation={0}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          openFilterMenu={openFilterMenu}
          title={library?.name}
          setShowAddProductDialog={setShowAddProductDialog}
          deleteAll={deleteAll}
        />
        <NormalDialog
          openDialog={showAddProductDialog}
          handleCloseShare={() => setShowAddProductDialog(false)}
          pageTitle="Add new product"
          content={
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    value={productTitle}
                    onChange={(e) => setProductTitle(e.target.value)}
                    placeholder="Title"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    value={productCategory}
                    onChange={(e) => setProductCategory(e.target.value)}
                    placeholder="Category"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    value={productSubCategory}
                    onChange={(e) => setProductSubCategory(e.target.value)}
                    placeholder="Sub Category"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    value={productUnit}
                    onChange={(e) => setProductUnit(e.target.value)}
                    placeholder="Unit"
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Box
                display="flex"
                justifyContent="flex-end"
                style={{ marginTop: "10px" }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => addNewItemToLib()}
                >
                  Save
                </Button>
              </Box>
            </Box>
          }
          loading={false}
        />
        <Popover
          id={showFilterMenu ? "simple-popover" : undefined}
          open={showFilterMenu}
          anchorEl={filterMenuAnchor}
          onClose={() => setShowFilterMenu(false)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <div
            style={{
              padding: "16px",
              minWidth: 300,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <Select
                  displayEmpty
                  variant="outlined"
                  value={categoryFilter}
                  onChange={(e) => setCategoryFilter(e.target.value)}
                >
                  <MenuItem value="">Categories (All)</MenuItem>
                  {categories?.map((item, i) => (
                    <MenuItem key={i} value={item}>{item}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <Select
                  displayEmpty
                  variant="outlined"
                  value={subCategoryFilter}
                  onChange={(e) => setSubCategoryFilter(e.target.value)}
                >
                  <MenuItem value="">Sub Categories (All)</MenuItem>
                  {subCategories?.map((item, i) => (
                    <MenuItem key={i} value={item}>{item}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                marginTop: "10px",
              }}
            >
              <Button
                onClick={() => {
                  closeFilterMenu();
                }}
                size="small"
                variant="outlined"
                color="primary"
                style={{ marginRight: 4 }}
              >
                Clear
              </Button>
              <Button
                onClick={() => applyFilters()}
                size="small"
                variant="contained"
                color="primary"
              >
                Apply
              </Button>
            </div>
          </div>
        </Popover>
        {loading ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{ width: "100%", height: "100vh" }}
          >
            <CircularProgress color="primary" />
          </Box>
        ) : (
          <TableContainer
            component={Paper}
            style={{ width: "100%" }}
            elevation={0}
          >
            <Table
              aria-labelledby="tableTitle"
              size="medium"
              sx={{ minWidth: 700 }}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                onSelectAllClick={handleSelectAllClick}
                rowCount={rows.length}
                columns={columns}
              />
              <TableBody>
                {rows.map((row, index) => {
                  const isItemSelected = isSelected(row._id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row._id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row._id}
                      selected={isItemSelected}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        {currentPage * PAGE_SIZE - 10 + index + 1}
                      </TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.category}</TableCell>
                      <TableCell>{row.subCategory}</TableCell>
                      <TableCell>{row.unit}</TableCell>

                      {/* <TableCell>
                        <Chip
                          label={row.approved ? "APPROVED" : "PENDING"}
                          style={{
                            color: row.approved ? "#15803d" : "#b91c1c",
                            borderColor: row.approved ? "#15803d" : "#b91c1c",
                            cursor: "pointer",
                          }}
                          variant="outlined"
                        />
                      </TableCell> */}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{ padding: "10px" }}
        >
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={(evt, newPage) => {
              setCurrentPage(newPage);
            }}
            variant="outlined"
            shape="rounded"
          />
        </Box>
      </Paper>
    </Box>
  );
};

export default ProjectLibraryView;
