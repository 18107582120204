import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery, ButtonBase, Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import clsx from "clsx";
import config from "../../config/index";

const useStyles = makeStyles((theme) => ({
  buttonStyle: {
    alignSelf: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    borderRadius: "3%",
    boxShadow: "none",
    borderWidth: "1px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      marginLeft: 0,
      marginBottom: 0,
    },
  },
  iconStyles: {
    color: theme.palette.action.disabled,
    margin: 0,
    height: "2rem",
    width: "2rem",
  },
  iconStylesSelected: {
    color: theme.palette.primary.main,
    margin: 0,
    height: "2rem",
    width: "2rem",
  },
  textStyle: {
    fontSize: "12px",
    marginTop: "5px",
    color: theme.palette.action.disabled,
    fontWeight: "450",
    alignSelf: "flex-start",
  },
  selected: {
    color: theme.palette.primary.main,
  },
}));

export default function Navbottons(props) {
  const classes = useStyles();
  const location = useLocation();
  const pathname = location["pathname"];
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const Icon = props.icon;
  const index = props.index;
  let link = 'https://reallist.ai' + props.link;
  const text = props.text;
  const selectedBtn = props?.selectedBtn || null;
  const notSelectedBtn = props?.notSelectedBtn || null;
  const selected =
    pathname.slice(0, 5) === link.toLowerCase().slice(0, 5) ? true : false;
  const iconClass = selected ? classes.iconStylesSelected : classes.iconStyles;

  return (
    <Box
      // marginBottom={isMobile ? "0rem" : "1.3rem"}
      // marginTop={isMobile ? "0rem" : "0"}
      paddingY={isMobile ? "0rem" : "12px"}
      display={"flex"}
      flexDirection={"row"}
      justifyContent="center"
      width={isMobile ? "20%" : "100%"}
      maxWidth={"100%"}
      sx={{
        backgroundColor: selected ? "#F5F7FA" : "transparent",
        borderLeft: selected
          ? `4px solid ${theme.palette.primary.main}`
          : "4px solid transparent",
        borderRight: "4px solid transparent",
      }}
    >
      <ButtonBase
        classes={{ root: classes.buttonStyle }}
        className={classes.buttonStyle}
        onClick={() => {
          window.open(link,"_self");
        }}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          width={"100%"}
        >
          {selectedBtn && notSelectedBtn ? (
            <>{selected ? selectedBtn : notSelectedBtn}</>
          ) : (
            <Icon fontSize="large" className={iconClass} />
          )}
          {isMobile ? null : (
            <Typography
              className={clsx(classes.textStyle, selected && classes.selected)}
              style={{ width: "100%" }}
            >
              {text}
            </Typography>
          )}
        </Box>
      </ButtonBase>
    </Box>
  );
}
