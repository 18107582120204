import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import NormalDrawer from "../styled/CommonComponents/NormalDrawer";
import { Avatar, IconButton, Paper, Button } from "@material-ui/core";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PurchaseOrder from "./PurchaseOrder";
import WorkOrder from "./WorkOrder";
import Transactions from "./Transactions";
import Certificate from "./Certificate";
import { getContactDetailsById } from "./api.call";
import Skeleton from '@material-ui/lab/Skeleton';
import CreateIcon from '@material-ui/icons/Create';
import CreatePalDrawer from "../AddMember/CreatePalDrawer";
import { getProfileForEntitySearch } from "../styled/CommonComponents/api.call";
import ContactBasicData from "./ContactBasicData";
import ContactDataDetails from "./ContactDataDetails";
import ContactAnalysisDashboard from "./Contact.Analysis.Dashboard";

const useStyles = makeStyles((theme) => ({
    mainCont: {
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        padding: "20px"
    },
    rightSideBar: {
        height: "100%",
        width: "400px",
        padding: "35px 20px 20px",
        position: "relative",
        overflowY: "auto"
    },
    editIcon: {
        position: "absolute",
        top: "10px",
        right: "10px"
    },
    leftSideBar: {
        height: "100%",
        width: "calc(100% - 440px)"
    },
    tabMainCont: {
        width: "100%",
        height: "calc(100% - 50px)",
    },
    picNameCont: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        "& h3": {
            fontSize: "18px",
            fontWeight: "500",
        },
        "& p": {
            fontSize: "14px",
            fontWeight: "400",
            color: "gray"
        },
        "& .MuiAvatar-root": {
            width: "90px",
            height: "90px",
            marginBottom: "10px"
        }
    },
    allRolesCont: {
        display: "flex",
        alignItems: "center",
        "& .MuiButton-containedSizeSmall": {
            borderRadius: "3px",
            padding: "1px 10px"
        }
    },
    roleStyle: {
        fontSize: "15px",
        fontWeight: "500",
        color: "gray",
        padding: "2px 7px",
        borderRadius: "3px",
        cursor: "pointer",
        border: "1px solid gray",
        marginRight: "10px",
    },
    roleStyleSelected: {
        fontSize: "15px",
        fontWeight: "500",
        color: theme.palette.primary.main,
        padding: "2px 7px",
        borderRadius: "3px",
        border: `2px solid ${theme.palette.primary.main}`,
        marginRight: "10px",
        cursor: "pointer",
    },
    basicData: {
        marginTop: "30px",
        padding: "10px",
    },
    basicDetailsHeader: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        marginBottom: "10px",
        "& div": {
            width: "calc((100% - 105px)/2)",
            height: "2px",
            backgroundColor: "black",
        },
        "& h3": {
            width: "95px",
            fontSize: "18px",
            fontWeight: "500",
            color: "black",
            margin: "0px 5px"
        }
    },
    contactData: {
        marginTop: "30px",
        padding: "10px",
    },
    contactDetailsHeader: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        marginBottom: "10px",
        "& div": {
            width: "calc((100% - 145px)/2)",
            height: "2px",
            backgroundColor: "black",
        },
        "& h3": {
            width: "135px",
            fontSize: "18px",
            fontWeight: "500",
            color: "black",
            margin: "0px 5px"
        }
    }
}));

export default function ContactAnalysis({
    openRelationDetails, setOpenRelationDetails, financialRelation,
    curEntityId, curEntityProfileId, walletId, entity
}) {
    const history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme();

    const [loading, setLoading] = useState(true);
    const [contactsData, setContactsData] = useState([]);
    const [contactData, setContactData] = useState(null);
    const [curOption, setCurOption] = useState(0)
    const [openPal, setOpenPal] = useState(false);
    const [palAction, setPalAction] = useState("");
    const [profileIdArr, setProfileIdArr] = useState([]);
    const [mainProfileWitParent, setMainProfileWithParent] = useState(null);

    const getInitalContactData = () => {
        setLoading(true)
        getContactDetailsById({
            firstPartyProfileId: curEntityProfileId,
            secondPartyProfileId: financialRelation?.profile?._id
        })
            .then((data) => {
                setContactsData(data)
                setContactData(data.filter(s => s?._id == financialRelation?._id)[0])
                console.log(data)
                //setContactData(data)
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        if (financialRelation?._id && openRelationDetails) {
            getInitalContactData()
        }
    }, [financialRelation, openRelationDetails])

    useEffect(() => {
        if (curEntityId && entity) {
            getProfileForEntitySearch({
                entityId: curEntityId,
                type: entity,
            })
                .then((data) => {
                    const profiles = data?.profiles || [];
                    const currentProfileWithParent = data?.currentProfileWithParent;
                    let locProfileIdArr = new Set([...profiles]);
                    setProfileIdArr([...locProfileIdArr]);
                    setMainProfileWithParent(currentProfileWithParent);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [curEntityId, entity]);


    return (
        <NormalDrawer
            openDrawer={openRelationDetails}
            setOpenDrawer={setOpenRelationDetails}
            anchor={"right"}
            width={"100vw"}
            isTitleComp={true}
            title={<div className={classes.allRolesCont} >
                {contactsData && contactsData.map((contact) => (
                    <div
                        className={contact?._id == contactData?._id ? classes.roleStyleSelected : classes.roleStyle}
                        onClick={() => { setContactData(contact) }}
                    >
                        {contact?.type}
                    </div>
                ))}
                <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={() => {
                        setPalAction("RoleAdd")
                        setOpenPal(true)
                    }}
                >
                    Add Role
                </Button>
            </div>}
            content={<div className={classes.mainCont} >
                {loading ? (<>
                    <Skeleton variant="rect" className={classes.rightSideBar} />
                    <Skeleton variant="rect" className={classes.leftSideBar} />
                </>) : (<>
                    <Paper elevation={2} className={classes.rightSideBar} >
                        <IconButton
                            className={classes.editIcon}
                            onClick={() => {
                                setPalAction("Update")
                                setOpenPal(true)
                            }}
                        >
                            <CreateIcon />
                        </IconButton>
                        <div className={classes.picNameCont} >
                            <Avatar src={contactData?.profile?.parent?.displayPicture?.url} />
                            <h3>{contactData?.profile?.parent?.displayName}</h3>
                            <p>@{contactData?.profile?.parent?.username}</p>
                        </div>

                        {contactData?.profile?.parentModelName === "Pal" && (
                            <div className={classes.basicData} >
                                <div className={classes.basicDetailsHeader} >
                                    <div></div>
                                    <h3>Basic Data</h3>
                                    <div></div>
                                </div>
                                <ContactBasicData
                                    contactData={contactData}
                                />
                            </div>
                        )}

                        <div className={classes.contactData} >
                            <div className={classes.contactDetailsHeader} >
                                <div></div>
                                <h3>Contact Details</h3>
                                <div></div>
                            </div>
                            <ContactDataDetails
                                contactData={contactData}
                            />
                        </div>
                    </Paper>
                    <div className={classes.leftSideBar} >
                        <div>
                            <Tabs
                                value={curOption}
                                onChange={(event, newValue) => { setCurOption(newValue); }}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                            >
                                <Tab label="Analysis" />
                                <Tab label="Purchase Orders" />
                                <Tab label="Work Orders" />
                                <Tab label="Transactions" />
                                <Tab label="Certificate" />
                            </Tabs>
                        </div>
                        <Paper elevation={2} className={classes.tabMainCont}  >
                            {curOption === 0 && (
                                <ContactAnalysisDashboard
                                    curEntityId={curEntityId}
                                    secondPartyId={contactData?.profile?._id}
                                />
                            )}
                            {curOption === 1 && (
                                <PurchaseOrder
                                    curEntityId={curEntityId}
                                    secondPartyId={contactData?.profile?._id}
                                />
                            )}
                            {curOption === 2 && (
                                <WorkOrder
                                    curEntityId={curEntityId}
                                    secondPartyId={contactData?.profile?._id}
                                />
                            )}
                            {curOption === 3 && (
                                <Transactions
                                    secondPartyId={contactData?.profile?._id}
                                    curEntityProfileId={curEntityProfileId}
                                />
                            )}
                            {curOption === 4 && (
                                <Certificate
                                    secondPartyId={contactData?.profile?._id}
                                    walletId={walletId}
                                />
                            )}
                        </Paper>
                    </div>
                    <CreatePalDrawer
                        openPal={openPal}
                        setOpenPal={setOpenPal}
                        title={palAction === "RoleAdd" ? "Add Role" : "Update Contact"}
                        notAllowedRoles={contactsData.map((contact) => contact?.type)}
                        mainProfileWitParent={mainProfileWitParent}
                        profileIdArr={profileIdArr}
                        selectEntityFunction={(newRelation) => {
                            console.log(newRelation)
                            getInitalContactData()
                        }}
                        fRelationId={contactData?._id}
                        actionType={palAction}
                        orgtype={contactData?.profile?.parent?.type}
                    />
                </>)}
            </div>}
        />
    );
}