import React, { useEffect, useState } from "react";
import LoadingBox from "../../styled/generic/LoadingHandler";
import { Box, Grid, Typography } from "@mui/material";
import TaskCard from "../../planning/tasks/components/TaskCard";
import { useDispatch, useSelector } from "react-redux";
import Api from "../../../helpers/Api";

const TasksView = () => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(false);

  console.log("Error loading");
  const getAssignedTasks = async () => {
    try {
      setLoading(true);
      const { data } = await Api.post("/wbs/assigned", {
        profileId: user?.profile,
      });
      console.log("dataaaaaaaaaa", data);
      if (data) {
        setTasks(data);
      } else {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "An unknown error occurred while fetching tasks",
          },
        });
      }
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred while fetching tasks",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAssignedTasks();
  }, []);

  return (
    <LoadingBox loading={loading} fullHeight={true}>
      <Box sx={{ mt: 2 }}>
        {tasks?.length <= 0 ? (
          <Box>
            <Typography variant="subtitle1" color="textSecondary">
              No tasks found
            </Typography>
          </Box>
        ) : (
          <Box>
            <Grid container spacing={2}>
              {tasks?.map((task, index) => (
                <Grid key={task?._id} item xs={12} md={6} lg={4} spacing={2}>
                  <TaskCard task={task} tasks={tasks} setTasks={setTasks} />
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
      </Box>
    </LoadingBox>
  );
};

export default TasksView;
