import {
  Box,
  Grid,
  IconButton,
  MenuItem,
  Pagination,
  Slider,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import BoxSpaceBetween from "../../styled/generic/BoxSpaceBetween";
import SingleUserWithCaptionTableCell from "../../styled/generic/SingleUserWithCaptionTableCell";
import HorizBox from "../../styled/generic/HorizBox";
import { useDispatch } from "react-redux";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import Api from "../../../helpers/Api";
import LoadingBox from "../../styled/generic/LoadingHandler";
import Button from "../../styled/generic/Button";
import Dialog from "../../styled/generic/Dialog";
import FormBox from "../../styled/generic/FormBox";
import TextField from "../../styled/generic/TextField";
import SpaceBetween from "../../styled/generic/SpaceBetween";
import DocumentUploader from "../../styled/generic/DocumentUploader";
import { useSelector } from "react-redux";
import DocViewer from "../../styled/generic/DocViewer";
import DuoButtonGroup from "../../styled/generic/DuoButtonGroup";
import { EditOutlined } from "@material-ui/icons";
import StatusHandler from "../../styled/generic/StatusHandler";
import StatusChip from "../../styled/generic/StatusChip";
import DrawerContainer from "../../styled/generic/DrawerContainer";
import { getProgressColor } from "../../../helpers/colorHelper";
import SearchField from "../../styled/generic/SearchField";
import Select from "../../styled/generic/Select";
import { Add } from "@mui/icons-material";
import AddTaskDialog from "./components/AddTaskDialog";
import TaskCard from "./components/TaskCard";

const ListView = ({ target, reload }) => {
  const { targetId } = useParams();
  const dispatch = useDispatch();
  const ORDER_OPTIONS = ["DATE CREATED", "PROGRESS", "START DATE"];

  const [searchStr, setSearchStr] = useState("");
  const [orderFilter, setOrderFilter] = useState("");
  const [orderFilterOrder, setOrderFilterOrder] = useState("Ascending");
  const [showAddTaskDialog, setShowAddTaskDialog] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [defaultOrg, setDefaultOrg] = useState("");
  const [defaultProject, setDefaultProject] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [editUnits, setEditUnits] = useState([]);
  const [editBlocks, setEditBlocks] = useState([]);

  const [totalPages, setTotalPages] = useState(1);

  const getTasksForListView = async () => {
    try {
      setLoading(true);
      const { data } = await Api.post("/wbs/", {
        targetId: targetId,
        searchStr: searchStr,
        orderFilter: orderFilter,
        orderFilterOrder: orderFilterOrder,
        onlyRootTasks: true,
        pageNumber: pageNumber,
        enablePagination: true,
      });

      if (data) {
        setDefaultOrg(data?.target?.organization);
        setDefaultProject(data?.target?.project);
        setTasks(data?.data || []);
        setTotalPages(data?.totalPages || 1);
      } else {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "An unknown error occurred while fetching tasks",
          },
        });
      }
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred while fetching tasks",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTasksForListView();
  }, [searchStr, orderFilter, orderFilterOrder, pageNumber, reload]);

  return (
    <Box>
      <BoxSpaceBetween>
        <Box flex="0.5">
          <SearchField
            value={searchStr}
            onChange={(e) => setSearchStr(e.target.value)}
            sx={{
              backgroundColor: "white",
              boxShadow: "0px 4px 5px 0px rgba(0, 0, 0, 0.04)",
            }}
            fullWidth={true}
            size="small"
          />
        </Box>
        <HorizBox>
          <Select
            value={orderFilter}
            onChange={(e) => setOrderFilter(e.target.value)}
            displayEmpty
            size="small"
          >
            <MenuItem value="">Filter (None)</MenuItem>
            {ORDER_OPTIONS.map((option) => (
              <MenuItem value={option}>{option}</MenuItem>
            ))}
          </Select>

          <Select
            value={orderFilterOrder}
            onChange={(e) => setOrderFilterOrder(e.target.value)}
            displayEmpty
            size="small"
          >
            <MenuItem value="Ascending">Ascending</MenuItem>
            <MenuItem value="Descending">Descending</MenuItem>
          </Select>

          <Button
            variant="outlined"
            color="primary"
            startIcon={<Add />}
            onClick={() => {
              setShowAddTaskDialog(true);
            }}
          >
            New
          </Button>
        </HorizBox>
      </BoxSpaceBetween>

      <LoadingBox loading={loading} fullHeight={true}>
        <Box sx={{ mt: 2 }}>
          {tasks?.length <= 0 ? (
            <Box>
              <Typography variant="subtitle1" color="textSecondary">
                No tasks found
              </Typography>
            </Box>
          ) : (
            <Box>
              <Grid container spacing={2}>
                {tasks.map((task, index) => (
                  <Grid item xs={12} md={6} lg={4} spacing={2}>
                    <TaskCard
                      setEditUnits={setEditUnits}
                      setEditBlocks={setEditBlocks}
                      task={task}
                      setTasks={setTasks}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}
        </Box>
        <AddTaskDialog
          showAddTaskDialog={showAddTaskDialog}
          setShowAddTaskDialog={setShowAddTaskDialog}
          editBlocks={editBlocks}
          editUnits={editUnits}
          defaultOrg={defaultOrg}
          defaultProject={defaultProject}
          targetId={targetId}
          onTaskAdd={(newTask) => {
            setTasks((prev) => [...prev, newTask]);
          }}
          profileId={target?.organization?.profile}
          library={target?.buildingCodeLibrary}
          project={target?.project}
          organization={target?.organization}
        />
      </LoadingBox>

      <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
        <Pagination
          count={totalPages}
          page={pageNumber}
          onChange={(e, page) => setPageNumber(page)}
          color="primary"
          variant="outlined"
          shape="rounded"
        />
      </Box>
    </Box>
  );
};

export default ListView;
