const allStatusOptions = {
  "Material Request": {
    Draft: ["Submitted"],
    Submitted: ["Under Review", "Rejected"],
    "Under Review": ["Approved", "Rejected"],
    Approved: ["Draft", "Rejected"],
    Rejected: ["Draft"],
  },
  "Bill Of Quantities": {
    Draft: ["Submitted"],
    Submitted: ["Evaluated", "Rejected"],
    Evaluated: ["Approved", "Rejected"],
    Approved: ["Draft", "Rejected"],
    Rejected: ["Draft"],
  },
  "Purchase Order": {
    Draft: ["Submitted"],
    Submitted: ["Approved", "Rejected"],
    Approved: ["Issued", "Rejected"],
    Issued: ["Acknowledged", "Rejected"],
    Acknowledged: ["Partially Fulfilled", "Fully Fulfilled", "Rejected"],
    "Fully Fulfilled": ["Draft", "Rejected"],
    "Partially Fulfilled": ["Draft", "Rejected"],
    Rejected: ["Draft"],
  },
  "Work Order": {
    Draft: ["Submitted"],
    Submitted: ["Approved", "Rejected"],
    Approved: ["Issued", "Rejected"],
    Issued: ["Acknowledged", "Rejected"],
    Acknowledged: ["Partially Fulfilled", "Fully Fulfilled", "Rejected"],
    "Fully Fulfilled": ["Draft", "Rejected"],
    "Partially Fulfilled": ["Draft", "Rejected"],
    Rejected: ["Draft"],
  },
  GRN: {
    Draft: ["Submitted"],
    Submitted: ["Approved", "Rejected"],
    Approved: ["Draft", "Rejected"],
    Rejected: ["Draft"],
  },
  Estimation: {
    Draft: ["Submitted"],
    Submitted: ["Approved", "Rejected"],
    Approved: ["Draft"],
    Rejected: ["Draft"],
  },
  Transaction: {
    Draft: ["Submitted"],
    Submitted: ["Under Review", "Rejected"],
    "Under Review": ["Approved", "Rejected"],
    Approved: ["Partially Paid", "Paid", "Rejected"],
    "Partially Paid": ["Paid", "Rejected"],
    Rejected: ["Draft"],
  },
  "Transaction Template": {
    Draft: ["Submitted"],
    Submitted: ["Approved", "Rejected"],
    Approved: ["Draft"],
    Rejected: ["Draft"],
  },
  "Financial Certificate": {
    Draft: ["Submitted"],
    Submitted: ["Approved", "Rejected"],
    Approved: ["Draft"],
    Rejected: ["Draft"],
  },
  DemandDeed: {
    Draft: ["Sent", "Checking"],
    Sent: ["Paid"],
    Checking: ["Sent", "Cancelled"],
    Cancelled: ["Draft"],
    Paid: [],
  },
  "Debit/Credit Note": {
    Draft: ["Submitted"],
    Submitted: ["Under Review", "Rejected"],
    "Under Review": ["Approved", "Rejected"],
    Approved: ["Draft"],
    Rejected: ["Draft"],
  },
  HRM: {
    Draft: ["Submitted"],
    Submitted: ["Under Review", "Rejected"],
    "Under Review": ["Approved", "Rejected"],
    Approved: ["Draft"],
    Rejected: ["Draft"],
  },
  MultiFamilySalesBooking: {
    Draft: ["Draft", "Checking", "Cancelled"],
    Checking: ["Confirmed", "In Progress"],
    Confirmed: ["In Progress"],
    "In Progress": ["Confirmed", "Cancelled"],
    Cancelled: ["Draft"],
    Paid: [],
  },
};
export { allStatusOptions };
