import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import HorizBox from "../../../styled/generic/HorizBox";
import { Avatar, Box, Typography } from "@mui/material";
import dayjs from "dayjs";
import { getProgressColor } from "../../../../helpers/colorHelper";
import Api from "../../../../helpers/Api";

const Updates = () => {
  const dispatch = useDispatch();
  const { targetId } = useParams();

  const [updates, setUpdates] = useState([]);
  useEffect(() => {
    getUpdates();
  }, []);

  const getUpdates = async () => {
    try {
      const { data } = await Api.post("/target/updates", {
        targetId: targetId,
      });
      console.log("kollllllllllll", data);
      if (data) {
        setUpdates(data);
      } else {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "Unable to get updates",
          },
        });
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to get updates",
        },
      });
    }
  };

  return (
    <Box>
      {updates.map((update) => (
        <Box
          sx={{
            borderRadius: "16px",
            border: "1px solid #DCDCDC",
            background: "#F3F4F5",
            p: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mt: 2,
          }}
        >
          <HorizBox>
            <Avatar src={update?.creator?.parent?.displayPicture?.url} />
            <Box>
              <Typography variant="h6">Progress Update</Typography>
              <Typography variant="subtitle1">
                {dayjs(update?.createdAt).format("DD/MM/YYYY")}
              </Typography>
            </Box>
          </HorizBox>
          <Box
            sx={{
              width: "200px",
              backgroundColor: getProgressColor(update?.progress)
                ?.backgroundColor,
              color: getProgressColor(update?.progress)?.color,
              p: 1,
              borderRadius: "8px",
            }}
          >
            <Typography variant="h6">Work Done</Typography>
            <Typography variant="subtitle1">{update?.progress}%</Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default Updates;
