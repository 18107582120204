import React from "react";

const Divider = () => {
  return (
    <div
      style={{
        height: "1px",
        width: "100%",
        backgroundColor: "#DCDCDC",
        margin: "16px 0",
      }}
    ></div>
  );
};

export default Divider;
