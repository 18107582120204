import React, { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from "styled-components";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Paper } from "@material-ui/core";
import { addUpdateBudgetMapping, getBudgetMapping, getDeepPopulatedBudgetCost } from "./api.call";
import NormalDrawer from "../styled/CommonComponents/NormalDrawer";
import emptyFile from "../../Assets/emptyData.svg"
import Skeleton from '@material-ui/lab/Skeleton';
import BudgetUpdateHeadBody from "./BudgetUpdateHeadBody";
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles((theme) => ({
    bodyCont: {
        width: "100%",
        height: "calc(100% - 60px)",
        overflowY: "auto",
        overflowX: "hidden",
        "& .MuiAccordionDetails-root": {
            padding: "0px",
            display: "block"
        },
        "& .MuiPaper-elevation1": {
            boxShadow: "none",
        },
        "& .MuiAccordionSummary-root.Mui-expanded": {
            minHeight: "0px",
            height: "60px",
        }
    },
    emptyLeftSideCont: {
        width: "100%",
        height: "calc(100% - 45px)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        "& img": {
            width: "30%",
            height: "auto",
        },
        "& div": {
            width: "100%",
            marginTop: "10px",
            padding: "0px 20px",
        },
        "& p": {
            fontSize: "14px",
            fontWeight: "400",
            color: "gray",
            textAlign: "center",
        },
        "& a": {
            textDecoration: "none",
            fontSize: "14px",
            marginLeft: "5px",
            cursor: "pointer",
            color: "blue"
        }
    },
    skeletonCont: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    searchSkeleton: {
        width: "70%",
        height: "40px",
        borderRadius: "5px",
        margin: "15px 0px 20px"
    },
    budgetCostSkeleton: {
        width: "100%",
        height: "50px",
        marginBottom: "10px"
    },
    bottomBtnCont: {
        width: "100%",
        height: "55px",
        borderTop: "1px solid #ececec",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0px 10px"
    },
    sectionTitle: {
        padding: "10px",
        borderBottom: "1px solid #ececec",
        "& h3": {
            fontSize: "17px",
            fontWeight: "500",
        }
    },
    selectedBudgetCont: {
        height: "200px",
        width: "100%",
        overflowX: "hidden",
        overflowY: "auto",
        display: "flex",
        flexWrap: 'wrap',
        alignItems: "baseline",
        alignContent: "baseline",
        "& div": {
            padding: "2px 5px",
            borderRadius: "3px",
            margin: "7px"
        }
    }
}));

const BudgetMapping = ({
    projectProfileId, dataId, dataModel
}) => {

    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);

    const [openUpdateBudget, setOpenUpdateBudget] = useState(false)
    const [headSubheadWorkitemData, setHeadSubheadWorkitemData] = useState([])
    const [parentChildrenMap, setParentChildrenMap] = useState({})
    const [amountChangeFlag, setAmountChangeFlag] = useState(false)
    const [loader, setLoader] = useState(false)
    const [updateLoader, setUpdateLoader] = useState(false)
    const [selectedBudgetId, setSelectedBudgetId] = useState([])
    const [connectedBudgetIds, setConnectedBudgetIds] = useState([])
    const [connectedBudgets, setConnectedBudgets] = useState([])
    const [idObjectMap, setIdObjectMap] = useState({})

    //api call to get all connected budgets
    const getConnectedBudgetIds = async () => {
        await getBudgetMapping({
            dataId,
            dataModel
        })
            .then((data) => {
                console.log(data)
                let letIds = [];
                data.map((s) => {
                    if (s?.workitem) {
                        letIds.push(s?.workitem)
                    } else {
                        letIds.push(s?.subworkhead)
                    }
                })
                setSelectedBudgetId(letIds)
                setConnectedBudgetIds(letIds)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    //load connected budgets
    useEffect(() => {
        if (openUpdateBudget && dataId && dataModel) {
            getConnectedBudgetIds()
        }
    }, [dataId, dataModel, openUpdateBudget])

    //load connected budgets objects
    useEffect(() => {
        let objArr = connectedBudgetIds.map((s) => idObjectMap[s])
        objArr.sort((a, b) => b.name.length - a.name.length);
        setConnectedBudgets(objArr)
    }, [connectedBudgetIds, idObjectMap])

    //helper function
    const buildHierarchy = (items, parent = null) => {
        const result = [];
        for (const item of items) {
            if (item.parent == parent) {
                const children = buildHierarchy(items, item._id);
                if (children.length > 0) {
                    item.children = children;
                }
                if (item.numberOfChildren === 0) {
                    result.push({
                        ...item,
                    })
                } else {
                    result.push(item);
                }
            }
        }
        return result;
    }

    //get budgets to select for update
    const getDeepPopulatedData = async () => {
        setLoader(true)
        setAmountChangeFlag(!amountChangeFlag)
        await getDeepPopulatedBudgetCost({ profileId: projectProfileId })
            .then(async (data) => {
                let updatedData = [...data]
                let objParentChildren = {}
                let locIdObjectMap = {}
                data.map((budgetCost, i) => {
                    budgetCost.budgetHead.map((budgetHead, j) => {
                        budgetHead.items.map((subhead, k) => {
                            locIdObjectMap[subhead?._id] = subhead;
                            let allWorkItem = subhead?.items || []
                            let workitemIds = allWorkItem.map((obj) => {
                                locIdObjectMap[obj?._id] = obj;
                                return obj?._id;
                            })
                            let parentChildMap = new Map();
                            allWorkItem.forEach(item => {
                                item.ancestors.forEach(parentId => {
                                    if (!parentChildMap.has(parentId)) {
                                        parentChildMap.set(parentId, []);
                                    }
                                    parentChildMap.get(parentId).push(item._id);
                                });
                            });
                            for (const [key, value] of parentChildMap) {
                                objParentChildren[key] = value
                            }
                            workitemIds.map((id) => {
                                if (!objParentChildren[id]) {
                                    objParentChildren[id] = []
                                }
                            })
                            objParentChildren[subhead?._id] = workitemIds
                            let stuctedWorkItems = buildHierarchy(allWorkItem)
                            updatedData[i].budgetHead[j].items[k].items = stuctedWorkItems;
                        })
                    })
                })
                setParentChildrenMap(objParentChildren)
                setHeadSubheadWorkitemData(updatedData)
                setLoader(false)
                setAmountChangeFlag(!amountChangeFlag)
                setIdObjectMap(locIdObjectMap)
            })
            .catch((err) => {
                console.log(err)
                setLoader(false)
            })
    }

    //load budget options
    useEffect(() => {
        if (projectProfileId && openUpdateBudget) {
            getDeepPopulatedData()
        }
    }, [projectProfileId, openUpdateBudget])

    //Handle check
    const handleCheck = (curId, isChecked) => {
        let arr = []
        let objMap = {}
        let allChildren = parentChildrenMap[curId]
        let allIds = [...allChildren, curId]
        if (isChecked) {
            const setArr = new Set([curId, ...selectedBudgetId])
            arr = [...setArr]
        } else {
            arr = selectedBudgetId.filter(item => item != curId);
        }
        setSelectedBudgetId(arr)
    }

    //save selected budgets
    const onSaveSubmit = async () => {
        let subheads = [];
        let subheadIds = [];
        let workitems = [];
        let filteredWorkitems = [];

        selectedBudgetId.map((s) => {
            const myObj = idObjectMap?.[s];
            if (myObj?.subworkhead) {
                let locAncestor = myObj?.ancestors || [];
                if (locAncestor.length == 0 || !locAncestor.some(ancestor => selectedBudgetId.includes(ancestor))) {
                    workitems.push(myObj)
                }
            } else {
                subheads.push(myObj)
                subheadIds.push(myObj?._id)
            }
        })

        workitems.map((s) => {
            if (!subheadIds.includes(s?.subworkhead)) {
                filteredWorkitems.push(s)
            }
        })

        setUpdateLoader(true)
        await addUpdateBudgetMapping({
            workorders: filteredWorkitems.map((s) => {
                return {
                    budgetCost: s?.budgetCost,
                    workhead: s?.workhead,
                    subworkhead: s?.subworkhead,
                    workitem: s?._id
                }
            }),
            subheads: subheads.map((s) => {
                return {
                    budgetCost: s?.budgetCost,
                    workhead: s?.budgetCostHead,
                    subworkhead: s?._id
                }
            }),
            dataId: dataId,
            dataModel: dataModel
        })
            .then((data) => {
                console.log(data)
                getConnectedBudgetIds()
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setUpdateLoader(false)
            })

    }

    return (<>
        <Button
            variant="outlined"
            color="primary"
            size="small"
            style={{ borderRadius: "5px" }}
            onClick={() => {
                setOpenUpdateBudget(true);
            }}
        >
            Update Budget
        </Button>
        <NormalDrawer
            openDrawer={openUpdateBudget}
            setOpenDrawer={setOpenUpdateBudget}
            anchor={"right"}
            title={"Mapping Budget"}
            width={"40vw"}
            content={<>
                <div>
                    {updateLoader && (<LinearProgress />)}
                </div>
                <div className={classes.bodyCont} >
                    <div>
                        <div className={classes.sectionTitle} >
                            <h3>Linked Estimate(s)</h3>
                        </div>
                        <div className={classes.selectedBudgetCont} >
                            {connectedBudgets && connectedBudgets.map((s) => (
                                <Paper
                                    style={s?.subworkhead ? { backgroundColor: "#05d505" } : { backgroundColor: "#3caaf9" }}
                                    elevation={2}
                                >
                                    {s?.name}
                                </Paper>
                            ))}
                        </div>
                    </div>
                    <div >
                        <div className={classes.sectionTitle}  >
                            <h3>Linked Estimate(s)</h3>
                        </div>
                        {loader ? (<div className={classes.skeletonCont} >
                            <Skeleton variant="rect" className={classes.searchSkeleton} />
                            <Skeleton variant="rect" className={classes.budgetCostSkeleton} />
                            <Skeleton variant="rect" className={classes.budgetCostSkeleton} />
                            <Skeleton variant="rect" className={classes.budgetCostSkeleton} />
                            <Skeleton variant="rect" className={classes.budgetCostSkeleton} />
                            <Skeleton variant="rect" className={classes.budgetCostSkeleton} />
                            <Skeleton variant="rect" className={classes.budgetCostSkeleton} />
                            <Skeleton variant="rect" className={classes.budgetCostSkeleton} />
                            <Skeleton variant="rect" className={classes.budgetCostSkeleton} />
                            <Skeleton variant="rect" className={classes.budgetCostSkeleton} />
                        </div>) : (<>
                            {headSubheadWorkitemData && headSubheadWorkitemData.length > 0 ? (<>
                                <BudgetUpdateHeadBody
                                    projectProfileId={projectProfileId}
                                    headSubheadWorkitemData={headSubheadWorkitemData}
                                    parentChildrenMap={parentChildrenMap}
                                    selectedBudgetId={selectedBudgetId}
                                    setSelectedBudgetId={setSelectedBudgetId}
                                    handleCheck={handleCheck}
                                    isFromBudgetMapping={true}
                                />
                            </>) : (<div className={classes.emptyLeftSideCont} >
                                <img src={emptyFile} />
                                <div>
                                    <p>No budget item is available.
                                        <a
                                            onClick={() => {
                                                history.push(`/budget-analysis/budget-costs/${projectProfileId}`)
                                            }}
                                        >
                                            click here
                                        </a> to create new budget item.</p>
                                </div>
                            </div>)}
                        </>)}
                    </div>
                </div>
                <div className={classes.bottomBtnCont} >
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={updateLoader}
                        onClick={onSaveSubmit}
                    >
                        Save & Close
                    </Button>
                </div>
            </>}
        />
    </>);
};

export default BudgetMapping;