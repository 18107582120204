import React, { useEffect, useState } from "react";
import Api from "../../../../helpers/Api";
import _ from "lodash";
import { Autocomplete, TextField, Box, MenuItem,FormControl,Select } from "@mui/material";
import Typography from "@material-ui/core/Typography";

const useBlockUnitViewer = ({ projectId, initialBlockIds, initialUnitIds }) => {
  const [blocks, setBlocks] = useState([]);
  const [units, setUnits] = useState([]);
  const [selectedBlocks, setSelectedBlocks] = useState(initialBlockIds || []);
  const [selectedUnits, setSelectedUnits] = useState(initialUnitIds || []);
  const [showBlocks, setShowBlock] = useState(true);
  const [loading, setLoading] = useState(false);
  const [selectedBlockForUnits, setSelectedBlockForUnits] = useState("");
  console.log("The initial blocks are:" , initialBlockIds);
  console.log("The initial units are:" , initialUnitIds);
  const handleChange = (event, newValue) => {
    setSelectedBlocks(newValue);
  };
  const getAllUnitIds = () => {
    let allIds = [];

    for (let unitId in selectedUnits) {
      allIds.push(...selectedUnits[`${unitId}`].map(({ _id }) => _id));
    }
    return allIds;
  };

  const handleBlockChange = (event, newValue) => {
    setSelectedBlocks(newValue);
    if (newValue.length > 0) {
      setSelectedBlockForUnits(newValue[0]._id);
    } else {
      setSelectedBlockForUnits("");
    }
  };

  const handleUnitBlockChange = (event) => {
    setSelectedBlockForUnits(event.target.value);
  };

  const getDropdownLabel = () => {
    if (selectedBlocks.length === 0) return "Choose blocks";
    const selectedBlock = selectedBlocks.find(block => block._id === selectedBlockForUnits);
    return selectedBlock ? selectedBlock.name : "Choose a block";
  };
  const getBlocks = async () => {
    try {
      if (
        projectId === "EMPTY" &&
        initialBlockIds?.length > 0 &&
        selectedBlocks?.length > 0
      ) {
        setSelectedBlocks([]);
        setSelectedUnits([]);
        setBlocks([]);
        setUnits([]);
        setShowBlock(false);
        return;
      }

      if (projectId === "EMPTY" && !initialBlockIds?.length) {
        setSelectedBlocks([]);
        setSelectedUnits([]);
        setBlocks([]);
        setUnits([]);
        setShowBlock(false);
        return;
      }
      if (
        projectId === "EMPTY" &&
        !initialBlockIds?.length > 0 &&
        selectedBlocks?.length > 0
      ) {
        setSelectedBlocks([]);
        setSelectedUnits([]);
        setBlocks([]);
        setUnits([]);
        setShowBlock(false);
        return;
      }
      if (projectId !== "EMPTY") {
        setLoading(true);
        const { data, rentalUnits } = await Api.post(
          "/public/project/block/get",
          {
            projectId: projectId,
            getRentalUnits: true,
          }
        );
        let groupByBlocks = _.groupBy(rentalUnits, "projectBlock");

        setLoading(false);
        setUnits(groupByBlocks);
        setBlocks(data);
        setShowBlock(true);

        // if (projectId !== defaultProjectId) {
        //   setBlocks(data);
        //   setSelectedBlocks([]);
        //   setSelectedUnits([]);
        // }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDefaultProjectBlocks = async () => {
    try {
      if (
        projectId === "EMPTY" &&
        initialBlockIds?.length > 0 &&
        selectedBlocks?.length > 0
      ) {
        setSelectedBlocks([]);
        setSelectedUnits([]);
        setBlocks([]);
        setUnits([]);
        setShowBlock(false);
        return;
      }

      if (
        projectId === "EMPTY" &&
        !initialBlockIds?.length > 0 &&
        selectedBlocks?.length > 0
      ) {
        setSelectedBlocks([]);
        setSelectedUnits([]);
        setBlocks([]);
        setUnits([]);
        setShowBlock(false);
        return;
      }

      if (projectId !== "EMPTY") {
        setShowBlock(false);
        setLoading(true);
        const { data, rentalUnits } = await Api.post(
          "/public/project/block/get",
          {
            projectId: projectId,
            getRentalUnits: true,
          }
        );

        let groupByBlocks = _.groupBy(rentalUnits, "projectBlock");

        let v = false;

        for (let unit in groupByBlocks) {
          if (groupByBlocks[unit]?.length > 0) v = true;
        }

        if (v) {
          getBlocks();
        }

        setUnits(groupByBlocks);
        setBlocks(data);
        setLoading(false);

        if (initialBlockIds?.length > 0) {
          const obj = Object.assign(groupByBlocks);

          for (let blockId in obj) {
            obj[blockId] = obj[blockId]?.filter((val) => {
              if (initialUnitIds?.includes(val?._id)) {
                return val;
              }
            });
          }

          let isVaildUnits = false;
          for (let val in obj) {
            if (obj[val]?.length > 0) isVaildUnits = true;
          }
          setSelectedUnits(isVaildUnits ? obj : {});
          let allBlocks = [];

          for (let i = 0; i < initialBlockIds?.length; i++) {
            let blockId = initialBlockIds[i];
            for (let j = 0; j < data?.length; j++) {
              let val = data[j];
              if (val?._id === blockId) {
                allBlocks.push(val);
              }
            }
          }

          setSelectedBlocks(allBlocks?.length > 0 ? allBlocks : []);
        }
        setShowBlock(true);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (initialBlockIds?.length > 0 || initialUnitIds?.length > 0) {
      getDefaultProjectBlocks();
    } else {
      getBlocks();
    }
  }, [projectId]);

  const blockUnitView = (
    <>
    {showBlocks && projectId && (
      <Box
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "0.8rem",
          paddingBottom: "2rem",
        }}
      >
        <Box component="span" sx={{ fontSize: "21px", fontWeight: "600", color: "#193B56" }}>
          Blocks
        </Box>
        <Box sx={{ display: "grid", gridTemplateColumns: "repeat(1,1fr)", width: "100%" }}>
          <Autocomplete
            multiple
            limitTags={4}
            id="multiple-limit-tags"
            onChange={handleBlockChange}
            value={selectedBlocks}
            options={blocks || []}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField {...params} placeholder="Add Block" />
            )}
          />
        </Box>
      </Box>
    )}
    {showBlocks && projectId && (
      <Box
        sx={{
          width: "100%",
          paddingBottom: "2rem",
          display: "flex",
          flexDirection: "column",
          gap: "0.8rem",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <Box component="span" sx={{ fontSize: "21px", fontWeight: "600", color: "#193B56" }}>
            Units
          </Box>
          <FormControl sx={{ minWidth: 120, marginLeft: 2 }}>
            <Select
              value={selectedBlockForUnits}
              onChange={handleUnitBlockChange}
              displayEmpty
              renderValue={() => getDropdownLabel()}
            >
              {selectedBlocks.map((block) => (
                <MenuItem key={block._id} value={block._id}>
                  {block.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        {selectedBlocks.length === 0 ? (
          <TextField
            fullWidth
            disabled
            label="No blocks selected"
            value="Select a block to view units"
            sx={{ width: "100%" }}
          />
        ) : (
          <Autocomplete
            multiple
            limitTags={4}
            value={selectedUnits[selectedBlockForUnits] || []}
            onChange={(e, newValue) => {
              setSelectedUnits({...selectedUnits, [selectedBlockForUnits]: newValue});
            }}
            options={units[selectedBlockForUnits] || []}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField {...params} label={getDropdownLabel()} placeholder="Add Unit" />
            )}
            disabled={!selectedBlockForUnits}
          />
        )}
      </Box>
    )}
  </>
);

return {
  blockUnitView,
  selectedUnitIds: Object.values(selectedUnits).flat().map(unit => unit._id),
  selectedBlockIds: selectedBlocks.map(({ _id }) => _id),
  loading,
};
};

export default useBlockUnitViewer;

// import React, { useEffect, useState } from "react";
// import Api from "../../../../helpers/Api";
// import _ from "lodash";
// import { Autocomplete, TextField, Box, MenuItem, FormControl, Select } from "@mui/material";
// import Typography from "@material-ui/core/Typography";

// const useBlockUnitViewer = ({ projectId = '', initialBlockIds = [], initialUnitIds = [] }) => {
//   const [blocks, setBlocks] = useState([]);
//   const [units, setUnits] = useState([]);
//   const [selectedBlocks, setSelectedBlocks] = useState(initialBlockIds || []);
//   const [selectedUnits, setSelectedUnits] = useState(initialUnitIds || []);
//   const [showBlocks, setShowBlock] = useState(true);
//   const [loading, setLoading] = useState(false);
//   const [selectedBlockForUnits, setSelectedBlockForUnits] = useState("");

//   const handleBlockChange = (event, newValue) => {
//     setSelectedBlocks(newValue);
//     if (newValue.length > 0) {
//       setSelectedBlockForUnits(newValue[0]._id);
//     } else {
//       setSelectedBlockForUnits("");
//     }
//   };

//   const handleUnitBlockChange = (event) => {
//     setSelectedBlockForUnits(event.target.value);
//   };

//   const getDropdownLabel = () => {
//     if (selectedBlocks.length === 0) return "Choose blocks";
//     const selectedBlock = selectedBlocks.find(block => block._id === selectedBlockForUnits);
//     return selectedBlock ? selectedBlock.name : "Choose a block";
//   };

//   const getBlocks = async () => {
//     try {
//       if (projectId === "EMPTY") {
//         setSelectedBlocks([]);
//         setSelectedUnits([]);
//         setBlocks([]);
//         setUnits([]);
//         setShowBlock(false);
//         return;
//       }

//       if (projectId !== "EMPTY") {
//         setLoading(true);
//         const { data, rentalUnits } = await Api.post("/public/project/block/get", {
//           projectId: projectId,
//           getRentalUnits: true,
//         });

//         let groupByBlocks = _.groupBy(rentalUnits, "projectBlock");

//         setLoading(false);
//         setUnits(groupByBlocks);
//         setBlocks(data);
//         setShowBlock(true);
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const getDefaultProjectBlocks = async () => {
//     try {
//       if (projectId === "EMPTY") {
//         setSelectedBlocks([]);
//         setSelectedUnits([]);
//         setBlocks([]);
//         setUnits([]);
//         setShowBlock(false);
//         return;
//       }

//       if (projectId !== "EMPTY") {
//         setShowBlock(false);
//         setLoading(true);
//         const { data, rentalUnits } = await Api.post("/public/project/block/get", {
//           projectId: projectId,
//           getRentalUnits: true,
//         });

//         let groupByBlocks = _.groupBy(rentalUnits, "projectBlock");

//         setUnits(groupByBlocks);
//         setBlocks(data);
//         setLoading(false);

//         if (initialBlockIds?.length > 0) {
//           const obj = Object.assign({}, groupByBlocks);

//           for (let blockId in obj) {
//             obj[blockId] = obj[blockId]?.filter((val) => {
//               if (initialUnitIds?.includes(val?._id)) {
//                 return val;
//               }
//             });
//           }

//           let isValidUnits = false;
//           for (let val in obj) {
//             if (obj[val]?.length > 0) isValidUnits = true;
//           }
//           setSelectedUnits(isValidUnits ? obj : {});
//           let allBlocks = [];

//           for (let i = 0; i < initialBlockIds?.length; i++) {
//             let blockId = initialBlockIds[i];
//             for (let j = 0; j < data?.length; j++) {
//               let val = data[j];
//               if (val?._id === blockId) {
//                 allBlocks.push(val);
//               }
//             }
//           }

//           setSelectedBlocks(allBlocks?.length > 0 ? allBlocks : []);
//         }
//         setShowBlock(true);
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   useEffect(() => {
//     if (initialBlockIds?.length > 0 || initialUnitIds?.length > 0) {
//       getDefaultProjectBlocks();
//     } else {
//       getBlocks();
//     }
//   }, [projectId, initialBlockIds, initialUnitIds]);

//   const blockUnitView = (
//     <>
//       {showBlocks && projectId && (
//         <Box
//           style={{
//             width: "100%",
//             display: "flex",
//             flexDirection: "column",
//             gap: "0.8rem",
//             paddingBottom: "2rem",
//           }}
//         >
//           <Box component="span" sx={{ fontSize: "21px", fontWeight: "600", color: "#193B56" }}>
//             Blocks
//           </Box>
//           <Box sx={{ display: "grid", gridTemplateColumns: "repeat(1,1fr)", width: "100%" }}>
//             <Autocomplete
//               multiple
//               limitTags={4}
//               id="multiple-limit-tags"
//               onChange={handleBlockChange}
//               value={selectedBlocks}
//               options={blocks || []}
//               getOptionLabel={(option) => option.name}
//               renderInput={(params) => (
//                 <TextField {...params} placeholder="Add Block" />
//               )}
//             />
//           </Box>
//         </Box>
//       )}
//       {showBlocks && projectId && (
//         <Box
//           sx={{
//             width: "100%",
//             paddingBottom: "2rem",
//             display: "flex",
//             flexDirection: "column",
//             gap: "0.8rem",
//           }}
//         >
//           <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
//             <Box component="span" sx={{ fontSize: "21px", fontWeight: "600", color: "#193B56" }}>
//               Units
//             </Box>
//             <FormControl sx={{ minWidth: 120, marginLeft: 2 }}>
//               <Select
//                 value={selectedBlockForUnits}
//                 onChange={handleUnitBlockChange}
//                 displayEmpty
//                 renderValue={() => getDropdownLabel()}
//               >
//                 {selectedBlocks.map((block) => (
//                   <MenuItem key={block._id} value={block._id}>
//                     {block.name}
//                   </MenuItem>
//                 ))}
//               </Select>
//             </FormControl>
//           </Box>
//           {selectedBlocks.length === 0 ? (
//             <TextField
//               fullWidth
//               disabled
//               label="No blocks selected"
//               value="Select a block to view units"
//               sx={{ width: "100%" }}
//             />
//           ) : (
//             <Autocomplete
//               multiple
//               limitTags={4}
//               value={selectedUnits[selectedBlockForUnits] || []}
//               onChange={(e, newValue) => {
//                 setSelectedUnits({...selectedUnits, [selectedBlockForUnits]: newValue});
//               }}
//               options={units[selectedBlockForUnits] || []}
//               getOptionLabel={(option) => option.name}
//               renderInput={(params) => (
//                 <TextField {...params} label={getDropdownLabel()} placeholder="Add Unit" />
//               )}
//               disabled={!selectedBlockForUnits}
//             />
//           )}
//         </Box>
//       )}
//     </>
//   );

//   return {
//     blockUnitView,
//     loading,
//     selectedUnitIds: Object.values(selectedUnits).flat().map(unit => unit?._id).filter(Boolean),
//     selectedBlockIds: selectedBlocks.map(({ _id }) => _id).filter(Boolean),
//     setSelectedBlockIds: (ids) => setSelectedBlocks(blocks.filter(block => ids.includes(block?._id))),
//     setSelectedUnitIds: (ids) => {
//       const newSelectedUnits = {};
//       Object.entries(units).forEach(([blockId, blockUnits]) => {
//         newSelectedUnits[blockId] = blockUnits.filter(unit => ids.includes(unit?._id));
//       });
//       setSelectedUnits(newSelectedUnits);
//     },
//   };
// };
// export default useBlockUnitViewer;
