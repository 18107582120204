import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    dataLineCont: {
        width: "100%",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        marginBottom: "5px",
        "& h3": {
            width: "140px",
            fontSize: "15px",
            fontWeight: "500",
            color: "gray"
        },
        "& p": {
            width: "calc(100% - 145px)",
            fontSize: "15px",
            fontWeight: "500",
            color: "black",
            paddingLeft: "10px",
        },
    }
}));

export default function ContactDataDetails({
    contactData
}) {
    const history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme();

    return (<>
        {contactData?.type === "Prospect" ? (<>
            <div className={classes.dataLineCont} >
                <h3>Listing</h3>
                <span>:</span>
                <p>{contactData?.firstPartyContact?.listingApp?.listing}</p>
            </div>
            <div className={classes.dataLineCont} >
                <h3>Title</h3>
                <span>:</span>
                <p>{contactData?.firstPartyContact?.requirement?.title}</p>
            </div>
            <div className={classes.dataLineCont} >
                <h3>Requirement Type</h3>
                <span>:</span>
                <p>{contactData?.firstPartyContact?.requirement?.requirementType}</p>
            </div>
            <div className={classes.dataLineCont} >
                <h3>Price Range</h3>
                <span>:</span>
                <p>{`${contactData?.firstPartyContact?.requirement?.minPrice} - ${contactData?.firstPartyContact?.requirement?.maxPrice}`}</p>
            </div>
            <div className={classes.dataLineCont} >
                <h3>Location</h3>
                <span>:</span>
                <p>{contactData?.firstPartyContact?.requirement?.locations.length > 0 ? (<>
                    {`${contactData?.firstPartyContact?.requirement?.locations[0]?.location?.address_line1}, ${contactData?.firstPartyContact?.requirement?.locations[0]?.location?.address_line2}`}
                </>) : ""}</p>
            </div>
            <div className={classes.dataLineCont} >
                <h3>Property Type</h3>
                <span>:</span>
                <p>{contactData?.firstPartyContact?.requirement?.propertyType}</p>
            </div>
            <div className={classes.dataLineCont} >
                <h3>Area Range</h3>
                <span>:</span>
                <p>{`${contactData?.firstPartyContact?.requirement?.minArea} - ${contactData?.firstPartyContact?.requirement?.maxArea}`}</p>
            </div>
            <div className={classes.dataLineCont} >
                <h3>Area Unit</h3>
                <span>:</span>
                <p>{contactData?.firstPartyContact?.requirement?.areaUnit}</p>
            </div>
            <div className={classes.dataLineCont} >
                <h3>Furnishing</h3>
                <span>:</span>
                <p>{contactData?.firstPartyContact?.requirement?.furnishings.length > 0 ? contactData?.firstPartyContact?.requirement?.furnishings.join(",") : ""}</p>
            </div>
            <div className={classes.dataLineCont} >
                <h3>Bedrooms</h3>
                <span>:</span>
                <p>{contactData?.firstPartyContact?.requirement?.bedRoomCount}</p>
            </div>
            <div className={classes.dataLineCont} >
                <h3>Bathroom</h3>
                <span>:</span>
                <p>{contactData?.firstPartyContact?.requirement?.bathRoomCount}</p>
            </div>
            <div className={classes.dataLineCont} >
                <h3>Balcony</h3>
                <span>:</span>
                <p>{contactData?.firstPartyContact?.requirement?.balconyCount}</p>
            </div>
            <div className={classes.dataLineCont} >
                <h3>Floor</h3>
                <span>:</span>
                <p>{contactData?.firstPartyContact?.requirement?.floors.length > 0 ? contactData?.firstPartyContact?.requirement?.floors.join(",") : ""}</p>
            </div>
            <div className={classes.dataLineCont} >
                <h3>Amenities</h3>
                <span>:</span>
                <p>{contactData?.firstPartyContact?.requirement?.amenities.length > 0 ? contactData?.firstPartyContact?.requirement?.amenities.join(",") : ""}</p>
            </div>
        </>) : (<>{contactData?.type === "Owner" ? (<>

        </>) : (<>{contactData?.type === "Customer" ? (<>

        </>) : (<>{contactData?.type === "Employee" ? (<>

        </>) : (<>{contactData?.type === "Contractor" ? (<>

        </>) : (<>{contactData?.type === "Vendor" ? (<>

        </>) : (<>{contactData?.type === "Investor" ? (<>

        </>) : (<>{contactData?.type === "Lender" ? (<>

        </>) : (<>{contactData?.type === "Tenant" ? (<>

        </>) : (<>{contactData?.type === "Worker" ? (<>

        </>) : (<>{contactData?.type === "Broker" ? (<>

        </>) : (<>{contactData?.type === "Admin" ? (<>

        </>) : (<>{contactData?.type === "Viewer" ? (<>

        </>) : (<>

        </>)}</>)}</>)}</>)}</>)}</>)}</>)}</>)}</>)}</>)}</>)}</>)}</>)}
    </>);
}