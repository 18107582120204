import React, { useEffect, useState } from "react";
import StandardContainer from "../../styled/generic/StandardContainer";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import {
  Avatar,
  AvatarGroup,
  Box,
  Grid,
  MenuItem,
  Pagination,
  Select,
  Typography,
} from "@mui/material";
import Button from "../../styled/generic/Button";
import HorizBox from "../../styled/generic/HorizBox";
import Api from "../../../helpers/Api";
import BoxSpaceBetween from "../../styled/generic/BoxSpaceBetween";
import StatusChip from "../../styled/generic/StatusChip";
import { getProgressColor } from "../../../helpers/colorHelper";
import SingleUserWithCaptionTableCell from "../../styled/generic/SingleUserWithCaptionTableCell";
import TaskCard from "./components/TaskCardDetail";
import StandardAppContainer from "../../styled/generic/StandardAppContainer";
import StandardAppContainerRounded from "../../styled/generic/StandardAppContainerRounded";
import FormHeaderWithActions from "../../styled/generic/FormHeaderWithActions";
import { Add } from "@mui/icons-material";
import FormBox from "../../styled/generic/FormBox";
import AddTaskDialog from "./components/AddTaskDialog";
import TaskProgressUpdateDrawer from "./components/TaskProgressUpdateDrawer";
import TabsWithCommonAction from "../../styled/generic/TabsWithCommonAction";
import StandardAppContainerRoundedWithHeader from "../../styled/generic/StandardAppContainerRoundedWithHeader";
import SmartCommentList from "../../comment/smart.comment.list";
import dayjs from "dayjs";
import SearchField from "../../styled/generic/SearchField";
import TableContainer from "../../styled/generic/TableContainer";
import { format } from "timeago.js";
import DocViewer from "../../styled/generic/DocViewer";
import Dialog from "../../styled/generic/Dialog";
import useShareAndAssign from "../../ResourceAccessControl/shareAndAssign";
import BudgetMapping from "../../budgetCostAnalysis/BudgetMapping";

const TaskDetails = () => {
  const { taskId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [task, setTask] = useState();
  const [showAddTaskDialog, setShowAddTaskDialog] = useState(false);
  const [target, setTarget] = useState();
  const [subTasks, setSubTasks] = useState([]);
  const [showUpdateTaskDialog, setShowUpdateTaskDialog] = useState(false);
  const [taskToUpdate, setTaskToUpdate] = useState(null);
  const [tab, setTab] = useState("Sub Tasks");
  const ORDER_OPTIONS = ["DATE CREATED", "PROGRESS", "START DATE"];
  const [searchStr, setSearchStr] = useState("");
  const [orderFilter, setOrderFilter] = useState("");
  const [orderFilterOrder, setOrderFilterOrder] = useState("Ascending");
  const [bugs, setBugs] = useState([]);
  const [filesArr, setFilesArr] = useState([]);
  const [fileIds, setFileIds] = useState([]);
  const [showProgressHistoryDialog, setShowProgressHistoryDialog] = useState(false);
  const [selectedProgressHistory, setSelectedProgressHistory] = useState();
  const [selectedProgressHistoryFiles, setSelectedProgressHistoryFiles] = useState([]);
  const [pageNumberST, setPageNumberST] = useState(1); // ST: SubTasks
  const [totalPagesST, setTotalPagesST] = useState(1);
  const [pageNumberB, setPageNumberB] = useState(1); // B: Bugs
  const [totalPagesB, setTotalPagesB] = useState(1);
  const [initialProfileRole, setInitialProfileRole] = useState({});

  const assignBtn = (
    <Button variant="outlined" startIcon={<Add />}>
      Click here to assign
    </Button>
  );

  const shareBtn = (
    <Button variant="outlined" color="primary">
      Manage Resource Access
    </Button>
  )

  const {
    sharedProfilesRole,
    ShareButton,
    ShareDrawer,
    SharedView,
    assignedProfiles,
    AssignButton,
    AssigneDrawer,
    AssignView,
  } = useShareAndAssign({
    initShared: initialProfileRole,
    initAssigned: null,
    resourceId: taskId,
    resourceName: 'WBS',
    customShareBtn: shareBtn,
    customAssignBtn: assignBtn,
  });


  const getTarget = async () => {
    try {
      setLoading(true);
      const { data } = await Api.post("/wbs/get-by-id", {
        wbsId: taskId,
      });
      if (data) {
        setTask(data);
        setTarget(data?.target);
        let _docs = data?.docs || [];
        let _files = [];
        for (let i = 0; i < _docs.length; i++) {
          let _doc = _docs[i];
          let _docFiles = _doc?.files || [];
          for (let j = 0; j < _docFiles.length; j++) {
            let _docFile = _docFiles[j];
            _files.push(_docFile);
          }
        }

        setFilesArr(_files);
        setFileIds(_files.map((f) => f?._id) || []);
      } else {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "An unknown error occurred while fetching target details",
          },
        });
      }
    } catch (error) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred while fetching target details",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const getSubTasks = async () => {
    try {
      setLoading(true);
      const { data } = await Api.post("/wbs/subtasks", {
        taskId: taskId,
        enablePagination: true,
        pageNumber: pageNumberST,
      });
      if (data) {
        setSubTasks(data?.subTasks);
        setTotalPagesST(data?.totalPages);
      } else {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "An unknown error occurred while fetching subtasks",
          },
        });
      }
    } catch (error) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred while fetching subtasks",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const getBugs = async () => {
    try {
      setLoading(true);
      const { data } = await Api.post("/bug/get-by-task", {
        taskId: taskId,
        pageNumber: pageNumberB,
      });
      if (data) {
        setBugs(data?.bugs);
        setTotalPagesB(data?.totalPages);
      } else {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "An unknown error occurred while fetching bugs",
          },
        });
      }
    } catch (error) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred while fetching bugs",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTarget();
    getSubTasks();
    getBugs();
  }, [taskId]);

  useEffect(() => {
    getSubTasks();
  }, [pageNumberST]);

  useEffect(() => {
    getBugs();
  }, [pageNumberB]);

  useEffect(() => {
    getSubTasks();
  }, [pageNumberST]);

  useEffect(() => {
    getBugs();
  }, [pageNumberB]);

  useEffect(() => {
    let _docs = selectedProgressHistory?.docs || [];
    let _files = [];
    for (let i = 0; i < _docs.length; i++) {
      let _doc = _docs[i];
      let _docFiles = _doc?.files || [];
      for (let j = 0; j < _docFiles.length; j++) {
        let _docFile = _docFiles[j];
        if (!_docFile) {
          continue;
        }
        _files.push(_docFile);
      }
    }

    setSelectedProgressHistoryFiles(_files);
  }, [selectedProgressHistory]);  

  return (
    <StandardContainer
      title="Task Details"
      showAppBar={true}
      appBarActions={
        <>
          <BudgetMapping
            projectProfileId={task?.project?.profile}
            dataId={task?._id}
            dataModel={"WBS"}
          />
          <span style={{ marginRight: "10px" }} ></span>
          {ShareButton}
          {ShareDrawer}
        </>
      }
      loading={loading}
      appBarTitle={task?.taskName || "Untitled"}
    >
      <StandardAppContainerRoundedWithHeader
        title="Task Details"
        icon=""
        padding={1}
      >
        <TaskCard
          fullTaskView={true}
          task={task}
          setTask={setTask}
          disableBorder={true}
          disableElevation={true}
        />
      </StandardAppContainerRoundedWithHeader>

      <TabsWithCommonAction
        tabs={[
          "Sub Tasks",
          "Progress History",
          "Comments",
          "Bugs",
          "Documents",
        ]}
        onTabChange={setTab}
        currentTabVariable={tab}
        action={<Box></Box>}
      />

      <Box>
        {tab === "Sub Tasks" && (
          <StandardAppContainerRounded>
            <BoxSpaceBetween>
              <Box flex="0.5">
                <SearchField
                  value={searchStr}
                  onChange={(e) => setSearchStr(e.target.value)}
                  sx={{
                    backgroundColor: "white",
                    boxShadow: "0px 4px 5px 0px rgba(0, 0, 0, 0.04)",
                  }}
                  fullWidth={true}
                  size="small"
                />
              </Box>
              <HorizBox>
                <Select
                  value={orderFilter}
                  onChange={(e) => setOrderFilter(e.target.value)}
                  displayEmpty
                  size="small"
                >
                  <MenuItem value="">Filter (None)</MenuItem>
                  {ORDER_OPTIONS.map((option) => (
                    <MenuItem value={option}>{option}</MenuItem>
                  ))}
                </Select>

                <Select
                  value={orderFilterOrder}
                  onChange={(e) => setOrderFilterOrder(e.target.value)}
                  displayEmpty
                  size="small"
                >
                  <MenuItem value="Ascending">Ascending</MenuItem>
                  <MenuItem value="Descending">Descending</MenuItem>
                </Select>

                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<Add />}
                  onClick={() => {
                    setShowAddTaskDialog(true);
                  }}
                >
                  New
                </Button>
              </HorizBox>
            </BoxSpaceBetween>
            {subTasks?.length > 0 ? (
              <Box>
                {subTasks.map((subTask) => (
                  <BoxSpaceBetween
                    mt={2}
                    sx={{
                      backgroundColor: "#F3F3F3",
                      borderRadius: "10px",
                      cursor: "pointer",
                      p: 2,
                    }}
                    onClick={() => {
                      history.push(`/planning/task/${subTask?._id}`);
                    }}
                  >
                    <HorizBox>
                      <Box
                        sx={{
                          backgroundColor: getProgressColor(subTask?.progress)
                            ?.backgroundColor,
                          p: 1,
                          borderRadius: "10px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          variant="h6"
                          sx={{
                            color: getProgressColor(subTask?.progress)?.color,
                          }}
                        >
                          {subTask?.progress || 0}%
                        </Typography>
                      </Box>

                      <Box>
                        <Typography variant="subtitle2">
                          {subTask?.taskName}
                        </Typography>
                        <Typography variant="body2">
                          {subTask?.description || "No description"}
                        </Typography>
                      </Box>
                    </HorizBox>
                    <Box>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={(e) => {
                          e.stopPropagation();
                          setTaskToUpdate(subTask);
                          setShowUpdateTaskDialog(true);
                        }}
                      >
                        Update
                      </Button>
                    </Box>
                  </BoxSpaceBetween>
                ))}
              </Box>
            ) : (
              <Box sx={{ p: 1 }}>No sub tasks found</Box>
            )}
            <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
              <Pagination
                count={totalPagesST}
                page={pageNumberST}
                onChange={(e, page) => setPageNumberST(page)}
                color="primary"
                variant="outlined"
                shape="rounded"
              />
            </Box>
          </StandardAppContainerRounded>
        )}

        {tab === "Progress History" && (
          <>
            <StandardAppContainerRounded>
              <Box>
                {task?.progressHistory?.length > 0 ? (
                  <Box>
                    {task?.progressHistory
                      ?.filter((item) => item !== null)
                      .map((progress, index) => (
                        <Box
                          mt={2}
                          sx={{
                            backgroundColor: "#F3F3F3",
                            borderRadius: "10px",
                            cursor: "pointer",
                            p: 2,
                          }}
                          onClick={() => {
                            setSelectedProgressHistory(progress);
                            setShowProgressHistoryDialog(true);
                          }}
                        >
                          <BoxSpaceBetween>
                            <Box>
                              <Typography variant="subtitle1">
                                PROGRESS UPDATE
                              </Typography>
                              <Typography variant="subtitle2">
                                {dayjs(progress?.createdAt).format(
                                  "DD MMM YYYY, hh:mm a"
                                )}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                width: "100px",
                                backgroundColor: getProgressColor(
                                  progress?.progress
                                )?.backgroundColor,
                                color: getProgressColor(progress?.progress)
                                  ?.color,
                                borderRadius: "10px",
                                display: "flex",
                                alignItems: "flex-end",
                                justifyContent: "center",
                                p: 1,
                                flexDirection: "column",
                              }}
                            >
                              <span
                                style={{
                                  fontSize: "1rem",
                                }}
                              >
                                Work Done
                              </span>
                              <span>{progress?.progress}/100</span>
                            </Box>
                          </BoxSpaceBetween>
                        </Box>
                      ))}
                  </Box>
                ) : (
                  <Box sx={{ p: 1 }}>No progress history found</Box>
                )}
              </Box>
            </StandardAppContainerRounded>
            <Dialog
              open={showProgressHistoryDialog}
              setOpen={setShowProgressHistoryDialog}
              title="Progress Update"
            >
              <Box>
                <FormBox label="Task">
                  <Typography variant="subtitle1">
                    {task?.taskName || "Untitled"}
                  </Typography>
                </FormBox>
                <FormBox label="Comments">
                  <Typography variant="body1">
                    {selectedProgressHistory?.comments || "No comments"}
                  </Typography>
                </FormBox>
                <FormBox label="Work Done">
                  <Typography variant="body1">
                    {selectedProgressHistory?.progress || 0}%
                  </Typography>
                </FormBox>
                <FormBox label="Work Left">
                  <Typography variant="body1">
                    {100 - (selectedProgressHistory?.progress || 0)}%
                  </Typography>
                </FormBox>
                <FormBox label="Date">
                  <Typography variant="body1">
                    {dayjs(selectedProgressHistory?.createdAt).format(
                      "DD MMM YYYY, hh:mm a"
                    )}
                  </Typography>
                </FormBox>
                <FormBox label="Documents">
                  <DocViewer
                    data={selectedProgressHistoryFiles}
                    isDeletable={false}
                    disableEditMetaData={true}
                    disableShowMetaData={true}
                    onMetadataChange={() => { }}
                    deleteFunction={() => { }}
                  />
                </FormBox>
              </Box>
            </Dialog>
          </>
        )}

        {tab === "Comments" && (
          <StandardAppContainerRounded>
            <SmartCommentList parent={task?._id} parentModelName="WBS" />
          </StandardAppContainerRounded>
        )}

        {tab === "Documents" && (
          <StandardAppContainerRounded>
            <Box>
              {filesArr?.length > 0 ? (
                <Box>
                  <DocViewer
                    data={filesArr}
                    isDeletable={true}
                    disableEditMetaData={true}
                    disableShowMetaData={true}
                    onMetadataChange={() => { }}
                    deleteFunction={(delId) => {
                      setFileIds(fileIds.filter((m) => m !== delId));
                      setFilesArr(filesArr.filter((m) => m._id !== delId));
                    }}
                  />
                </Box>
              ) : (
                <Box>No documents found</Box>
              )}
            </Box>
          </StandardAppContainerRounded>
        )}

        {tab === "Bugs" && (
          <StandardAppContainerRounded>
            <TableContainer
              columns={["TITLE", "LABELS", "PRIORITY", "ASSIGNEES"]}
              data={bugs}
              loading={loading}
            >
              {bugs.map((bug, index) => (
                <tr
                  onClick={() => {
                    history.push(`/bugs/bug/${bug?._id}`);
                  }}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <td>
                    <Box>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontWeight: "bold",
                        }}
                      >
                        {bug?.title} #{bug?.count}{" "}
                      </Typography>
                      <Typography variant="subtitle2" color="textSecondary">
                        #{bug?.count}{" "}
                        {bug?.type === "Open" ? "opened" : "closed"}{" "}
                        {bug?.type === "Open"
                          ? format(bug?.openDate)
                          : format(bug?.closeDate)}{" "}
                        by {bug?.user?.displayName}
                      </Typography>
                    </Box>
                  </td>
                  <td>
                    {bug?.labels?.length > 0 ? (
                      <Box>
                        {bug?.labels?.slice(0, 3)?.map((label) => (
                          <div
                            style={{
                              backgroundColor: label?.color + "33",
                              color: label?.color,
                              padding: "5px",
                              display: "inline-flex",
                              alignItems: "center",
                              justifyContent: "center",
                              marginRight: "5px",
                              fontSize: "0.8rem",
                              borderRadius: "8px",
                              margin: "5px",
                            }}
                          >
                            {label?.title || "Untitled"}
                          </div>
                        ))}
                      </Box>
                    ) : (
                      <Typography variant="subtitle2" color="textSecondary">
                        No labels
                      </Typography>
                    )}
                  </td>
                  <td>{bug?.priority}</td>
                  <td>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <AvatarGroup max={4}>
                        {bug?.assigned?.map((item) => (
                          <Avatar
                            alt={item?.parent?.displayName}
                            src={item?.parent?.displayPicture?.url}
                            sx={{
                              width: "32px",
                              height: "32px",
                            }}
                          />
                        ))}
                      </AvatarGroup>
                    </Box>
                  </td>
                </tr>
              ))}
            </TableContainer>
            <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
              <Pagination
                count={totalPagesB}
                page={pageNumberB}
                onChange={(e, page) => setPageNumberB(page)}
                color="primary"
                variant="outlined"
                shape="rounded"
              />
            </Box>
          </StandardAppContainerRounded>
        )}
      </Box>

      <AddTaskDialog
        showAddTaskDialog={showAddTaskDialog}
        setShowAddTaskDialog={setShowAddTaskDialog}
        targetId={target?._id}
        onTaskAdd={(newTask) => {
          history.push(`/planning/task/${newTask._id}`);
        }}
        profileId={target?.organization?.profile}
        library={target?.buildingCodeLibrary}
        project={target?.project?._id}
        organization={target?.organization}
        parentTaskId={task?._id}
      />
      <TaskProgressUpdateDrawer
        open={showUpdateTaskDialog}
        setOpen={setShowUpdateTaskDialog}
        setTasks={setSubTasks}
        taskToUpdate={taskToUpdate}
      />
    </StandardContainer>
  );
};

export default TaskDetails;
